import { ApiResponse } from "../interfaces/apiResponseInterface";
import { UserInterface } from "../interfaces/userInterface";
import axiosTeethTab from "./apiCall";

interface UserApiInterface {
  getUsers: () => Promise<ApiResponse<UserInterface[]>>;
  saveUser: (user: UserInterface) => Promise<ApiResponse<UserInterface>>;
}

const userApi: UserApiInterface = {
  getUsers: async () => {
    const endpoint = "users";
    return await axiosTeethTab.get<UserInterface[]>(endpoint);
  },
  saveUser: async (user) => {
    const endpoint = user._id ? `users/${user._id}` : "users";
    return await axiosTeethTab.post<UserInterface>(endpoint, user);
  },
};

export default userApi;
