import React, { useState } from "react";
import { FaUserAlt, FaCaretDown } from "react-icons/fa";
import ls, { get } from "local-storage";
import { AuthInterface } from "../interfaces/authInterface";

interface UserToolbarInterface {
  logout?: () => any;
}

const UserToolbar = ({ logout }: UserToolbarInterface) => {
  const [open, setOpen] = useState<boolean>(false);
  const userData = get<AuthInterface>("userData");

  const handleOpenClose = () => {
    setOpen((prev) => !prev);
  };
  if (!userData) {
    return <></>;
  }
  return (
    <>
      <div
        className="flex items-center cursor-pointer relative mr-2"
        onClick={handleOpenClose}
      >
        <FaUserAlt />
        <div className="pl-2 pr-1">
          <p className="m-0 text-xs font-bold">{userData.name}</p>
          <p className="m-0 text-xs text-slate-400">{userData.role.name}</p>
        </div>
        <FaCaretDown />
      </div>
      {open && (
        <div className="bg-white fixed top-11 md:top-10 right-2 w-36 text-center border border-gray-300 py-1">
          <p className="text-xs cursor-pointer" onClick={logout}>
            Cerrar Sessión
          </p>
        </div>
      )}
    </>
  );
};

export default UserToolbar;
