import React, { InputHTMLAttributes } from "react";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  error?: string;
  id: string;
}
const Checkbox = ({ label, id, checked, name, onChange }: Props) => {
  return (
    <div className="flex items-center">
      <label className="cursor-pointer" htmlFor={id}>
        <input
          className="mr-2 cursor-pointer"
          type="checkbox"
          id={id}
          name={name}
          checked={checked}
          onChange={onChange}
        />
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
