const formatToMoney = (value: any): string => {
  let numberValue =
    typeof value === "string"
      ? parseFloat(value)
      : typeof value === "number"
      ? value
      : NaN;

  if (isNaN(numberValue)) {
    numberValue = 0;
  }

  const parts = numberValue.toFixed(2).split(".");
  const integerPart = parts[0];

  // Add commas every three digits in the integer part
  const formattedIntegerPart = integerPart.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ","
  );

  // Combine the formatted integer part with the decimal part
  const formattedValue = `$${formattedIntegerPart}.${parts[1] || "00"}`;

  return formattedValue;
};

export default formatToMoney;
