import { create } from "zustand";
import { errorNotification, successNotification } from "../utils/notification";
import categoryApi from "../services/categoryApi";
import { CategoryInterface } from "../interfaces/categoryInterface";

const categoriesInit: CategoryInterface[] = [];

interface CateogiryStoreInterface {
  categories: CategoryInterface[];
  loading: boolean;
  error: string;
  resetError: () => void;
  getData: () => void;
  saveData: (role: CategoryInterface) => void;
}

const useCategoryStore = create<CateogiryStoreInterface>((set) => ({
  categories: categoriesInit,
  loading: false,
  error: "",
  resetError: () => {
    set({ error: "" });
  },
  getData: async () => {
    try {
      set({ loading: true, error: "" });
      const response = await categoryApi.getCategories();
      if (response.ok && response.data) {
        const { data } = response;
        set({ categories: data, loading: false });
      } else {
        set({ loading: false });
        errorNotification(
          "Oops! algo salió mal, inténtalo de nuevo más tarde."
        );
      }
    } catch (error) {
      set({ loading: false });
      errorNotification("Oops! algo salió mal, inténtalo de nuevo más tarde.");
    }
  },
  saveData: async (category: CategoryInterface) => {
    set({ loading: true });
    try {
      const response = await categoryApi.saveCategory(category);
      // Pendiente ver mejores practicas de backend para guardar y paginar contenido
      if (response.ok && response.data) {
        const { data } = response; // This line retrieves the data from the response object
        set({ loading: false, error: "" });
        successNotification("Datos guardados con éxito!");
        await useCategoryStore.getState().getData();
      } else {
        set({ loading: false, error: response.problem as string });
        errorNotification(
          "Oops! algo salió mal, inténtalo de nuevo más tarde."
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      errorNotification("Oops! algo salió mal, inténtalo de nuevo más tarde.");
      set({ loading: false });
    }
  },
}));

export default useCategoryStore;
