import { FormikProps } from "formik";
import {
  OrderInterface,
  ProductOrderInterface,
} from "../../../interfaces/orderInterface";
import SelectFilter from "../../../components/common/Inputs/SelectFilter";
import { useEffect, useState } from "react";
import useProductStore from "../../../stores/useProductStore";
import { ProductInterface } from "../../../interfaces/productInterface";
import CustomInput from "../../../components/common/Inputs/CustomInput";
import Button from "../../../components/common/Buttons/Button";
import { FaPlus, FaRegTimesCircle, FaSave } from "react-icons/fa";
import { ColumnInterface } from "../../../interfaces/customTableInterface";
import {
  CalcTotalPriceResInterface,
  calcTotalOrderPrice,
  getDiscountByTotalOrder,
  getProductTotalCalc,
  getSubTotalProductORder,
} from "../../../utils/calcTotalPrice";
import ProductsTabe from "./ProductsTabe";
import { DealerInterface } from "../../../interfaces/dealerInterface";
import { removeIVAFromPrice } from "../../../constants/orderFormulas";
import { ShipmentProductCategoryID } from "../../../constants/sistem";

interface Props {
  formik: FormikProps<OrderInterface>; // Define the formik prop
  dealers: DealerInterface[];
  selectedDealer?: DealerInterface;
  isTaxRegimeMoral?: boolean;
}

const columns: ColumnInterface<ProductOrderInterface>[] = [
  {
    key: "name",
    label: "Producto",
  },
  {
    key: "price",
    label: "Precio",
    isMoney: true,
  },
  {
    key: "quantity",
    label: "Cantidad",
  },
  {
    key: "total",
    label: "Total",
    isMoney: true,
  },
];

const ProductsForm = ({ formik, selectedDealer, isTaxRegimeMoral }: Props) => {
  const { products, getData } = useProductStore();
  const [selectedProduct, setSelectedProduct] = useState<ProductInterface>();
  const [quantity, setQuantity] = useState<number | undefined>();
  const [isEditing, setIsEditing] = useState<boolean>(false);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const { subTotal, iva, isr, total }: CalcTotalPriceResInterface =
      calcTotalOrderPrice(formik.values.products, isTaxRegimeMoral!);
    formik.setValues(
      {
        ...formik.values,
        subTotal,
        iva,
        isr,
        total,
      },
      false
    );
  }, [formik.values.products]);

  const handleProductOrderOnChange = (value: string) => {
    const product = products.find((x) => x._id === value);
    setSelectedProduct(product);
  };
  const calcProductOrderPrice = () => {
    // Get total price without discount
    const subTotalOrder = getSubTotalProductORder(formik.values.products);
    // discount calc
    const discount = formik.values.specialDiscount
      ? formik.values.specialDiscount
      : getDiscountByTotalOrder(subTotalOrder);
    const nList = formik.values.products.map((x) => {
      const product = products.find((p) => p._id === x._id);
      if (product) {
        const productPrice = removeIVAFromPrice(
          product?.price!,
          isTaxRegimeMoral
        );
        const totalWithoutDiscount = x.quantity * productPrice;
        const productPriceToSell =
          product.categoryId !== ShipmentProductCategoryID
            ? getProductTotalCalc(productPrice, discount)
            : totalWithoutDiscount;
        const productOrder: ProductOrderInterface = {
          _id: product?._id!,
          name: product?.name!,
          isBulk: product?.isBulk!,
          originalPrice: productPrice,
          price: productPriceToSell,
          quantity: x.quantity,
          totalWithoutDiscount: totalWithoutDiscount,
          total: productPriceToSell * x.quantity,
        };
        return productOrder;
      }
      return x;
    });
    formik.setFieldValue("products", nList);
  };

  useEffect(() => {
    calcProductOrderPrice();
  }, [selectedDealer, formik.values.specialDiscount]);

  const addProduct = () => {
    const product = products.find((x) => x._id === selectedProduct?._id);
    const productInList = formik.values.products.find(
      (x) => x._id === selectedProduct?._id
    );
    if (product) {
      // Get total price without discount
      let subTotalOrder = getSubTotalProductORder(formik.values.products);
      const productPrice = removeIVAFromPrice(product.price!, isTaxRegimeMoral);
      const productQuantity =
        isEditing || !productInList
          ? quantity!
          : productInList.quantity + quantity!;
      const totalWithoutDiscount = productQuantity * productPrice;
      if (productInList) {
        subTotalOrder -= productInList.totalWithoutDiscount;
        subTotalOrder += totalWithoutDiscount;
      }
      // discount calc
      const discount = formik.values.specialDiscount
        ? formik.values.specialDiscount
        : getDiscountByTotalOrder(subTotalOrder);
      const productPriceToSell =
        product.categoryId !== ShipmentProductCategoryID
          ? getProductTotalCalc(productPrice, discount)
          : totalWithoutDiscount;
      const productOrder: ProductOrderInterface = {
        _id: product?._id!,
        name: product?.name,
        isBulk: product.isBulk,
        originalPrice: productPrice,
        price: productPriceToSell,
        quantity: productQuantity,
        totalWithoutDiscount: totalWithoutDiscount,
        total: productPriceToSell * productQuantity,
      };
      const nList = productInList
        ? formik.values.products.map((x) =>
            x._id === productOrder._id ? productOrder : x
          )
        : [...formik.values.products, productOrder];

      formik.setFieldValue("products", nList);
      clearForm();
    }
  };

  const handleEdit = (product: ProductOrderInterface) => {
    const productToEdit = products.find((x) => x._id === product._id);
    setSelectedProduct(productToEdit);
    setQuantity(product.quantity);
    setIsEditing(true);
  };

  const handleDelete = (product: ProductOrderInterface) => {
    const nList = formik.values.products.filter((x) => x._id != product._id);
    formik.setFieldValue("products", nList);
  };

  const handleQuantityOnChange = (value: string) => {
    setQuantity(selectedProduct?.isBulk ? parseFloat(value) : parseInt(value));
  };

  const clearForm = () => {
    setSelectedProduct(undefined);
    setQuantity(undefined);
    setIsEditing(false);
  };

  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <SelectFilter
          label="Producto"
          data={products}
          dataKey={"_id"}
          dataLabel={"name"}
          selected={selectedProduct ? selectedProduct._id! : ""}
          onSelect={handleProductOrderOnChange}
          error={
            formik.touched.products &&
            formik.errors.products &&
            Array.isArray(formik.errors.products)
              ? formik.errors.products.join(", ")
              : formik.errors.products
          }
        />
        <div className="flex justify-between md:grid md:grid-cols-2 gap-4">
          <div className="col-span-1">
            <CustomInput
              label="Cantidad"
              type="number"
              onChange={(e) => handleQuantityOnChange(e.target.value)}
              value={quantity ? quantity?.toString() : ""}
              disabled={!selectedProduct}
            />
          </div>
          <div className="col-span-1 mt-4">
            <Button
              size="medium"
              color="primary"
              className="inline-block mt-2 h-11"
              onClick={addProduct}
            >
              {isEditing ? <FaSave /> : <FaPlus />}
            </Button>
            {isEditing && (
              <Button
                size="medium"
                color="secondary"
                className="inline-block mt-2 ml-2 h-11"
                onClick={clearForm}
              >
                <FaRegTimesCircle />
              </Button>
            )}
          </div>
        </div>
      </div>
      <ProductsTabe
        products={formik.values.products}
        onEdit={handleEdit}
        onDelete={handleDelete}
      />
    </div>
  );
};

export default ProductsForm;
