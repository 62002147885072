interface PaymentMethodInterface {
  value: string;
  label: string;
}

export const paymentMethods: PaymentMethodInterface[] = [
  {
    value: "cash",
    label: "Efectivo",
  },
  {
    value: "transfer",
    label: "Transferencia",
  },
  {
    value: "undefined",
    label: "Por definir",
  },
];

export const paymentMethodsForm: PaymentMethodInterface[] = [
  {
    value: "cash",
    label: "Efectivo",
  },
  {
    value: "transfer",
    label: "Transferencia",
  },
];
