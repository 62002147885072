import { create } from "zustand";
import { errorNotification, successNotification } from "../utils/notification";
import categoryApi from "../services/categoryApi";
import { ProductInterface } from "../interfaces/productInterface";
import productApi from "../services/productApi";

const productsInit: ProductInterface[] = [];

interface CateogiryStoreInterface {
  products: ProductInterface[];
  loading: boolean;
  error: string;
  resetError: () => void;
  getData: () => void;
  saveData: (role: ProductInterface) => void;
}

const useProductStore = create<CateogiryStoreInterface>((set) => ({
  products: productsInit,
  loading: false,
  error: "",
  resetError: () => {
    set({ error: "" });
  },
  getData: async () => {
    try {
      set({ loading: true, error: "" });
      const response = await productApi.getProducts();
      if (response.ok && response.data) {
        const { data } = response;
        set({ products: data, loading: false });
      } else {
        set({ loading: false });
        errorNotification(
          "Oops! algo salió mal, inténtalo de nuevo más tarde."
        );
      }
    } catch (error) {
      set({ loading: false });
      errorNotification("Oops! algo salió mal, inténtalo de nuevo más tarde.");
    }
  },
  saveData: async (product: ProductInterface) => {
    try {
      set({ loading: true, error: "" });
      const response = await productApi.saveProduct(product);
      // Pendiente ver mejores practicas de backend para guardar y paginar contenido
      if (response.ok && response.data) {
        const { data } = response; // This line retrieves the data from the response object
        set({ loading: false, error: "" });
        successNotification("Datos guardados con éxito!");
        await useProductStore.getState().getData();
      } else {
        set({ loading: false, error: response.problem as string });
        errorNotification(
          "Oops! algo salió mal, inténtalo de nuevo más tarde."
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      errorNotification("Oops! algo salió mal, inténtalo de nuevo más tarde.");
      set({ loading: false });
    }
  },
}));

export default useProductStore;
