import React from "react";
import Button from "./Buttons/Button";

interface PaginationProps {
  totalPages: number;
  currentPage: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  totalPages,
  currentPage,
  onPageChange,
}) => {
  const pagesToShow = 10;
  const halfPagesToShow = Math.floor(pagesToShow / 2);

  let startPage = Math.max(1, currentPage - halfPagesToShow);
  let endPage = Math.min(totalPages, startPage + pagesToShow - 1);

  if (endPage - startPage + 1 < pagesToShow) {
    startPage = Math.max(1, endPage - pagesToShow + 1);
  }

  const pages = Array.from(
    { length: endPage - startPage + 1 },
    (_, index) => startPage + index
  );
  return (
    <div className="md:flex items-center mt-4">
      <span className="mr-2">
        Página {currentPage} de {totalPages}
      </span>
      <ul className="flex space-x-2 overflow-x-auto">
        {totalPages > 10 && (
          <Button
            size="small"
            color="primary"
            inverted={currentPage != 1}
            onClick={() => onPageChange(1)}
          >
            Inicio
          </Button>
        )}
        {pages.map((page) => (
          <li key={page} data-id={page}>
            {page == currentPage ? (
              <Button
                size="small"
                color="primary"
                label={page.toString()}
                onClick={() => onPageChange(page)}
              />
            ) : (
              <Button
                size="small"
                color="primary"
                inverted={true}
                label={page.toString()}
                onClick={() => onPageChange(page)}
              />
            )}
          </li>
        ))}
        {totalPages > 10 && (
          <Button
            size="small"
            color="primary"
            inverted={currentPage != totalPages}
            onClick={() => onPageChange(totalPages)}
          >
            Final
          </Button>
        )}
      </ul>
    </div>
  );
};

export default Pagination;
