import SpaceBG from "../components/animatedbg/SpaceBG";
import Button from "../components/common/Buttons/Button";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen container404 flex justify-center items-center select-none">
      <SpaceBG />
      <div className="absolute">
        <div className="flex justify-center">
          <label>4</label>
          <img alt="0" src="/img/404.svg" className="filter404" />
          <label>4</label>
        </div>
        <Button
          size="medium"
          color="primary"
          className="block m-auto"
          onClick={() => navigate("/")}
        >
          Let's get you home
        </Button>
      </div>
    </div>
  );
};

export default PageNotFound;
