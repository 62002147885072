import { ApiResponse } from "../interfaces/apiResponseInterface";
import { DealerInterface } from "../interfaces/dealerInterface";
import axiosTeethTab from "./apiCall";

interface DealerApiInterface {
  getDealers: () => Promise<ApiResponse<DealerInterface[]>>;
  saveDealer: (
    dealer: DealerInterface
  ) => Promise<ApiResponse<DealerInterface>>;
}

const dealerApi: DealerApiInterface = {
  getDealers: async () => {
    const endpoint = "dealers";
    return await axiosTeethTab.get<DealerInterface[]>(endpoint);
  },
  saveDealer: async (dealer) => {
    const endpoint = dealer._id ? `dealers/${dealer._id}` : "dealers";
    return await axiosTeethTab.post<DealerInterface>(endpoint, dealer);
  },
};

export default dealerApi;
