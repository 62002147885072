import { ApiResponse } from "../interfaces/apiResponseInterface";
import { ProductInterface } from "../interfaces/productInterface";
import axiosTeethTab from "./apiCall";

interface ProductApiInterface {
  getProducts: () => Promise<ApiResponse<ProductInterface[]>>;
  saveProduct: (
    product: ProductInterface
  ) => Promise<ApiResponse<ProductInterface>>;
}

const productApi: ProductApiInterface = {
  getProducts: async () => {
    const endpoint = "products";
    return await axiosTeethTab.get<ProductInterface[]>(endpoint);
  },
  saveProduct: async (product) => {
    const endpoint = product._id ? `products/${product._id}` : "products";
    return await axiosTeethTab.post<ProductInterface>(endpoint, product);
  },
};

export default productApi;
