import { Navigate, useNavigate } from "react-router-dom";
import { AuthInterface } from "../interfaces/authInterface";
import ls, { get, remove } from "local-storage";

export const validateTokenExpirationDate = (expireAt: Date): boolean => {
  let isExpired = true;
  const now = new Date();

  if (now < new Date(expireAt)) {
    isExpired = false;
  }
  return isExpired;
};

export const logout = () => {
  remove("userData");
};
