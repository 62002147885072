import React, { useEffect, useState } from "react";
import { FormikHelpers, useFormik } from "formik";
import * as Yup from "yup";
import TeethtabBG from "../components/animatedbg/TeethtabBG";
import CustomInput from "../components/common/Inputs/CustomInput";
import Button from "../components/common/Buttons/Button";
import { AuthInterface, LoginInterface } from "../interfaces/authInterface";
import authApi from "../services/authApi";
import ls, { get, set } from "local-storage";
import { errorNotification, successNotification } from "../utils/notification";
import { useIsAuth } from "../utils/useAuth";
import { validateTokenExpirationDate } from "../utils/auth";
import { useNavigate } from "react-router-dom";

const formDataInit: LoginInterface = {
  userName: "",
  password: "",
};

const validationSchema = Yup.object().shape({
  userName: Yup.string()
    .min(2, "Nombre de usuario demaciado corto")
    .required("Campo requerido"),
  password: Yup.string()
    .min(8, "Contraseña demaciado corto, almenos debe tener 8 caracteres")
    .required("Campo requerido"),
});

const LoginPage = () => {
  const { login, loading, error } = useIsAuth();
  const navigate = useNavigate();
  const userData = get<AuthInterface>("userData");

  useEffect(() => {
    const isTokenExpired = validateTokenExpirationDate(userData?.expireAt);
    if (userData && !isTokenExpired) {
      navigate("/dashboard/home");
    }
  }, []);

  const formik = useFormik<LoginInterface>({
    initialValues: formDataInit,
    validationSchema: validationSchema,
    onSubmit: (
      values: LoginInterface,
      formikHelpers: FormikHelpers<LoginInterface>
    ) => {
      login(values);
      formikHelpers.setSubmitting(false); // You can perform additional actions here if needed
      formikHelpers.resetForm();
    },
  });

  const handleSubmit = () => {
    formik.handleSubmit();
  };

  return (
    <div className="min-h-screen flex justify-center items-center">
      <TeethtabBG />
      <form className="absolute bg-white rounded w-9/12 md:w-72 p-5">
        <div className="flex justify-center mb-2">
          <img alt="logo" src="img/logos/tt-logo.png" />
        </div>
        <CustomInput
          label="Username"
          type="text"
          error={
            formik.touched.userName &&
            formik.errors.userName &&
            Array.isArray(formik.errors.userName)
              ? formik.errors.userName.join(", ")
              : formik.errors.userName
          }
          {...formik.getFieldProps("userName")}
        />
        <CustomInput
          label="Password"
          type="password"
          error={
            formik.touched.password &&
            formik.errors.password &&
            Array.isArray(formik.errors.password)
              ? formik.errors.password.join(", ")
              : formik.errors.password
          }
          {...formik.getFieldProps("password")}
        />
        <Button
          size="medium"
          color="primary"
          className="w-full mt-2"
          onClick={handleSubmit}
        >
          Login
        </Button>
      </form>
    </div>
  );
};

export default LoginPage;
