import { useEffect, useState } from "react";
import Button from "../../components/common/Buttons/Button";
import CustomInput from "../../components/common/Inputs/CustomInput";
import Modal from "../../components/common/Modal";
import Title from "../../components/common/Title";
import { FormikHelpers, useFormik } from "formik";
import { ProductInterface } from "../../interfaces/productInterface";
import useProductStore from "../../stores/useProductStore";
import useCategoryStore from "../../stores/useCategoryStore";
import SelectInput, {
  OptionType,
} from "../../components/common/Inputs/SelectInput";
import { CategoryInterface } from "../../interfaces/categoryInterface";
import {
  ColumnInterface,
  ExtraDataInterface,
} from "../../interfaces/customTableInterface";
import CustomTable from "../../components/common/Table/CustomTable";
import productSchema from "../../schemas/productSchema";
import Checkbox from "../../components/common/Inputs/Checkbox";
import { UNAUTHORIZED_ERROR } from "../../constants/apiErrors";
import { useIsAuth } from "../../utils/useAuth";
import Loading from "../../components/Loading";

const formDataInit: ProductInterface = {
  name: "",
  categoryId: "",
  price: null,
  cost: null,
  isBulk: false,
  active: true,
};

const columns: ColumnInterface<ProductInterface>[] = [
  {
    key: "name",
    label: "Nombre",
  },
  {
    key: "categoryId",
    label: "Categoría",
  },
];

const ProductPage = () => {
  const { logout } = useIsAuth();
  const { loading, products, getData, saveData, error, resetError } =
    useProductStore();
  const { categories, getData: getCategories } = useCategoryStore();
  const [categoriesOptions, setCategoriesOptions] = useState<OptionType[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    getData();
    getCategories();
  }, []);

  useEffect(() => {
    if (error === UNAUTHORIZED_ERROR) {
      resetError();
      logout();
    }
  }, [error]);

  useEffect(() => {
    const nOptions: OptionType[] = categories.map(({ _id, name }) => {
      return {
        value: _id ? _id : "",
        label: name,
      };
    });
    setCategoriesOptions(nOptions);
  }, [categories]);

  const extraData: ExtraDataInterface<CategoryInterface>[] = [
    {
      src: categories,
      dataType: "categoryId",
    },
  ];

  const formik = useFormik<ProductInterface>({
    initialValues: formDataInit,
    validationSchema: productSchema,
    onSubmit: (
      values: ProductInterface,
      formikHelpers: FormikHelpers<ProductInterface>
    ) => {
      saveData(values);
      formikHelpers.setSubmitting(false); // You can perform additional actions here if needed
      formikHelpers.resetForm();
      handleOnClose();
    },
  });

  const handleOnClose = () => {
    setShowModal(false);
    formik.resetForm();
  };

  const handleEdit = (role: ProductInterface) => {
    setShowModal(true);
    formik.setValues(role);
  };

  return (
    <div>
      <Title title="Productos" />
      <div className="flex justify-end">
        <Button
          size="medium"
          onClick={() => setShowModal(true)}
          color="primary"
        >
          Nuevo
        </Button>
      </div>
      {loading ? (
        <Loading />
      ) : (
        <CustomTable
          tableId="product-table"
          data={products}
          columns={columns}
          onEdit={handleEdit}
          extraData={extraData}
        />
      )}
      <Modal
        show={showModal}
        close={handleOnClose}
        submit={formik.handleSubmit}
      >
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <CustomInput
            label="Producto"
            error={
              formik.touched.name &&
              formik.errors.name &&
              Array.isArray(formik.errors.name)
                ? formik.errors.name.join(", ")
                : formik.errors.name
            }
            {...formik.getFieldProps("name")}
            onChange={(e) => {
              formik.handleChange(e);
            }}
          />
          <SelectInput
            label="Categoría"
            options={categoriesOptions}
            error={
              formik.touched.categoryId &&
              formik.errors.categoryId &&
              Array.isArray(formik.errors.categoryId)
                ? formik.errors.categoryId.join(", ")
                : formik.errors.categoryId
            }
            {...formik.getFieldProps("categoryId")}
            onChange={(e) => {
              formik.handleChange(e);
            }}
          />
          <CustomInput
            label="Precio"
            type="number"
            error={
              formik.touched.price &&
              formik.errors.price &&
              Array.isArray(formik.errors.price)
                ? formik.errors.price.join(", ")
                : formik.errors.price
            }
            {...formik.getFieldProps("price")}
            onChange={(e) => {
              formik.handleChange(e);
            }}
          />
          <CustomInput
            label="Costo"
            type="number"
            error={
              formik.touched.cost &&
              formik.errors.cost &&
              Array.isArray(formik.errors.cost)
                ? formik.errors.cost.join(", ")
                : formik.errors.cost
            }
            {...formik.getFieldProps("cost")}
            onChange={(e) => {
              formik.handleChange(e);
            }}
          />
          <CustomInput
            label="Código de barras"
            error={
              formik.touched.barCode &&
              formik.errors.barCode &&
              Array.isArray(formik.errors.barCode)
                ? formik.errors.barCode.join(", ")
                : formik.errors.barCode
            }
            {...formik.getFieldProps("barCode")}
            onChange={(e) => {
              formik.handleChange(e);
            }}
          />
          <CustomInput
            label="SKU"
            error={
              formik.touched.sku &&
              formik.errors.sku &&
              Array.isArray(formik.errors.sku)
                ? formik.errors.sku.join(", ")
                : formik.errors.sku
            }
            {...formik.getFieldProps("sku")}
            onChange={(e) => {
              formik.handleChange(e);
            }}
          />
          <Checkbox
            label="es granel?"
            id={"isBulk"}
            name="isBulk"
            onChange={(e) => {
              formik.setFieldValue(
                "isBulk",
                formik.values.isBulk ? false : true
              );
            }}
            checked={formik.values.isBulk}
          />
        </div>
      </Modal>
    </div>
  );
};

export default ProductPage;
