import Button from "./Buttons/Button";
import { FaTimes } from "react-icons/fa";

interface ModalProps {
  title?: string;
  children?: React.ReactNode;
  close?: () => void;
  submit?: () => void;
  show: Boolean;
}
const Modal = ({ title, close, submit, show, children }: ModalProps) => {
  return (
    show && (
      <div className="absolute bg-modal z-50 w-full h-full top-0 left-0 top-0 overflow-y-auto">
        <div className="flex justify-center my-16 mx-4 md:mx-0">
          <div className="bg-white rounded max-w-2xl w-full">
            <div className="flex justify-between border-b border-slate-300 p-2 md:px-4">
              <span>{title}</span>
              <Button size="medium" color="primary" inverted onClick={close}>
                <FaTimes className="text-slate-500" />
              </Button>
            </div>
            <div className="p-6 md:p-4">{children}</div>
            <div className="flex justify-between border-t border-slate-300 px-6 md:px-4 py-2">
              <Button size="medium" color="primary" onClick={submit}>
                Guardar
              </Button>
              <Button size="medium" color="primary" onClick={close} inverted>
                Cancelar
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default Modal;
