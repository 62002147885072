import * as Yup from "yup";

const productOrderSchema = Yup.object().shape({
  _id: Yup.string(),
  isBulk: Yup.boolean(),
  name: Yup.string()
    .min(2, "Nombre demaciado corto")
    .required("Campo requerido"),
  price: Yup.number(),
  quantity: Yup.number(),
});

const orderSchema = Yup.object().shape({
  dealerId: Yup.string().required("Campo requerido"),
  status: Yup.string()
    .required("Campo requerido")
    .test("Is positive?", "Campo requerido", (value) => parseInt(value) > 0),
  paymentMethod: Yup.string().required("Campo requerido"),
  shipmentAddress: Yup.string().required("Campo requerido"),
  paymentType: Yup.string().required("Campo requerido"),
  products: Yup.array()
    .of(productOrderSchema)
    .test(
      "Is positive?",
      "Debe agregar almenos un producto",
      (value) => value && value?.length > 0
    ),
});

export default orderSchema;
