import { useEffect, useState } from "react";
import Button from "../../components/common/Buttons/Button";
import CustomInput from "../../components/common/Inputs/CustomInput";
import Modal from "../../components/common/Modal";
import CustomTable from "../../components/common/Table/CustomTable";
import Title from "../../components/common/Title";
import useCategoryStore from "../../stores/useCategoryStore";
import * as Yup from "yup";
import { FormikHelpers, useFormik } from "formik";
import { CategoryInterface } from "../../interfaces/categoryInterface";
import { ColumnInterface } from "../../interfaces/customTableInterface";
import categorySchema from "../../schemas/categorySchema";
import { UNAUTHORIZED_ERROR } from "../../constants/apiErrors";
import { useIsAuth } from "../../utils/useAuth";
import Loading from "../../components/Loading";

const formDataInit: CategoryInterface = {
  name: "",
  active: true,
};

const columns: ColumnInterface<CategoryInterface>[] = [
  {
    key: "name",
    label: "Nombre",
  },
];

const CategoryPage = () => {
  const { logout } = useIsAuth();
  const { loading, categories, getData, saveData, error, resetError } =
    useCategoryStore();
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (error === UNAUTHORIZED_ERROR) {
      resetError();
      logout();
    }
  }, [error]);

  const formik = useFormik<CategoryInterface>({
    initialValues: formDataInit,
    validationSchema: categorySchema,
    onSubmit: (
      values: CategoryInterface,
      formikHelpers: FormikHelpers<CategoryInterface>
    ) => {
      saveData(values);
      formikHelpers.setSubmitting(false); // You can perform additional actions here if needed
      formikHelpers.resetForm();
      handleOnClose();
    },
  });

  const handleOnClose = () => {
    setShowModal(false);
    formik.resetForm();
  };

  const handleEdit = (role: CategoryInterface) => {
    setShowModal(true);
    formik.setValues(role);
  };

  return (
    <div>
      <Title title="Categorías" />
      <div className="flex justify-end">
        <Button
          size="medium"
          onClick={() => setShowModal(true)}
          color="primary"
        >
          Nuevo
        </Button>
      </div>
      {loading ? (
        <Loading />
      ) : (
        <CustomTable
          tableId="category-table"
          data={categories}
          columns={columns}
          onEdit={handleEdit}
        />
      )}

      <Modal
        show={showModal}
        close={handleOnClose}
        submit={formik.handleSubmit}
      >
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <CustomInput
            label="Categoría"
            error={
              formik.touched.name &&
              formik.errors.name &&
              Array.isArray(formik.errors.name)
                ? formik.errors.name.join(", ")
                : formik.errors.name
            }
            {...formik.getFieldProps("name")}
            onChange={(e) => {
              formik.handleChange(e);
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default CategoryPage;
