import { create } from "zustand";
import { IOrderFilter, OrderInterface } from "../interfaces/orderInterface";
import orderApi from "../services/orderApi";
import { errorNotification, successNotification } from "../utils/notification";
import { IApiPagination, IPagination } from "../interfaces/IPagination";

interface OrderStoreInterface {
  orders: OrderInterface[];
  paginationData: IPagination;
  pagination: IApiPagination;
  setPagination: (key: string, value: number) => void;
  filter: IOrderFilter;
  setFilter: (key: string, value: string) => void;
  clearFilter: () => void;
  loading: boolean;
  error: string;
  resetError: () => void;
  getData: (filter: IOrderFilter, pagination: IApiPagination) => void;
  saveData: (order: OrderInterface) => void;
}

const ordersInit: OrderInterface[] = [];
const paginationDataInit: IPagination = {
  currentPage: 1,
  totalPages: 0,
  pageSize: 20,
  total: 0,
};

const filterInit: IOrderFilter = {
  orderNumber: "",
  dealerId: "",
  statusId: undefined,
  paymentStatus: "",
};

const paginationInit: IApiPagination = { page: 1, pageSize: 20 };
const useOrderStore = create<OrderStoreInterface>((set, get) => ({
  orders: ordersInit,
  paginationData: paginationDataInit,
  pagination: paginationInit,
  setPagination(key, value) {
    set((state) => ({
      pagination: {
        ...state.pagination,
        [key]: value,
      },
    }));
  },
  filter: filterInit,
  setFilter(key, value) {
    set((state) => ({
      filter: {
        ...state.filter,
        [key]: value,
      },
    }));
  },
  clearFilter: () => {
    set(() => ({
      filter: filterInit,
    }));
  },
  loading: false,
  error: "",
  resetError: () => {
    set({ error: "" });
  },
  getData: async (filter: IOrderFilter, pagination: IApiPagination) => {
    try {
      set({ loading: true, error: "" });
      const response = await orderApi.getOrders(filter, pagination);
      if (response.ok && response.data) {
        const { data } = response;
        set({
          orders: data.data.orders,
          paginationData: data.data.pagination,
          loading: false,
        });
      } else {
        set({ loading: false });
        errorNotification(
          "Oops! algo salió mal, inténtalo de nuevo más tarde."
        );
      }
    } catch (error) {
      set({ loading: false });
      errorNotification("Oops! algo salió mal, inténtalo de nuevo más tarde.");
    }
  },
  saveData: async (order: OrderInterface) => {
    try {
      set({ loading: true, error: "" });
      const response = await orderApi.saveOrders(order);
      // Pendiente ver mejores practicas de backend para guardar y paginar contenido
      if (response.ok && response.data) {
        const { data } = response; // This line retrieves the data from the response object
        const id = data._id;
        set({ loading: false, error: "" });
        successNotification("Datos guardados con éxito!");
        const pagination = get().pagination,
          filter = get().filter;
        await get().getData(filter, pagination);
        if (!order._id) {
          window.open(`/invoice/${id}`, "_blank");
        }
      } else {
        set({ loading: false, error: response.problem as string });
        errorNotification(
          "Oops! algo salió mal, inténtalo de nuevo más tarde."
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      errorNotification("Oops! algo salió mal, inténtalo de nuevo más tarde.");
      set({ loading: false });
    }
  },
}));

export default useOrderStore;
