import { FaFileDownload } from "react-icons/fa";
import Button from "../../Buttons/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

interface Props {
  id: string;
}

const handleOnClick = (id: string) => {
  window.open(`/invoice/${id}`, "_blank");
};

const DownloadPDF = ({ id }: Props) => {
  return (
    <Button
      size="small"
      color="primary"
      onClick={() => handleOnClick(id)}
      inverted
    >
      <FontAwesomeIcon icon={faDownload} />
    </Button>
  );
};

export default DownloadPDF;
