import React, { ReactElement, useEffect } from "react";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import Home from "./pages/Home";

import Login from "./pages/Login";
import PageNotFound from "./pages/PageNotFound";
import DashboardContainer from "./share/Dashboard";
import UserPage from "./pages/users/UserPage";
import RolesPage from "./pages/roles/RolesPage";
import CategoryPage from "./pages/categories/CategoryPage";
import ProductPage from "./pages/products/ProductPage";
import DealerPage from "./pages/dealers/DealerPage";
import OrdersPage from "./pages/orders/OrdersPage";
import Invoice from "./pages/orders/Invoice";

interface RoutesInterface {
  path: string;
  page: ReactElement;
}

const adminRoutes: RoutesInterface[] = [
  {
    path: "home",
    page: <Home />,
  },
  {
    path: "users",
    page: <UserPage />,
  },
  {
    path: "roles",
    page: <RolesPage />,
  },
  {
    path: "categories",
    page: <CategoryPage />,
  },
  {
    path: "products",
    page: <ProductPage />,
  },
  {
    path: "dealers",
    page: <DealerPage />,
  },
  {
    path: "orders",
    page: <OrdersPage />,
  },
];

const App = () => {
  return (
    <Routes>
      <Route index element={<Login />} />
      <Route path="/404" element={<PageNotFound />} />
      <Route path="/dashboard" element={<DashboardContainer />}>
        {adminRoutes.map((route) => (
          <Route key={route.path} path={route.path} element={route.page} />
        ))}
      </Route>
      <Route path="invoice/:id" element={<Invoice />} />
      <Route path="*" element={<Navigate to="/404" />} />
    </Routes>
  );
};
export default App;
