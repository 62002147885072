import { ReactNode, createContext, useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import ls, { get, remove, set } from "local-storage";
import { AuthInterface, LoginInterface } from "../interfaces/authInterface";
import { errorNotification, successNotification } from "./notification";
import authApi from "../services/authApi";
export interface AuthContextType {
  userData: AuthInterface;
  login: (data: LoginInterface) => void;
  logout: () => void;
  loading: boolean;
  error: string;
}

// Create the context
const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Define the props for AuthProvider
interface AuthProviderProps {
  children: ReactNode;
}

// Create AuthProvider as a React component
export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const userData = get<AuthInterface>("userData");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const navigate = useNavigate();

  const login = async (data: LoginInterface) => {
    setLoading(true);
    try {
      const res = await authApi.auth(data);
      setLoading(false);
      console.log(res);
      if (res.data?.token) {
        successNotification("Bienvenido !!");
        set("userData", res.data);
        navigate("/dashboard/home");
      } else {
        errorNotification(
          "Usuario o contraseña incorrectos, verifique sus datos"
        );
      }
      // ... rest of the code
    } catch (error) {
      console.error("Error during API call:", error);
      errorNotification("Se produjo un error intente más tarde");
      setLoading(false);
    }
  };

  const logout = () => {
    remove("userData");
    navigate("/", { replace: true });
  };

  const value = useMemo(
    () => ({
      userData,
      login,
      logout,
      loading,
      error,
    }),
    [userData]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

// Create a hook to access the AuthContext
export const useIsAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useIsAuth must be used within an AuthProvider");
  }
  return context;
};
