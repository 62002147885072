import React, { useMemo, ButtonHTMLAttributes } from "react";
import SecondaryButton from "./SecondaryButton";
import PrimaryButton from "./PrimaryButton";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string;
  color?: "primary" | "secondary";
  inverted?: boolean;
  size: "small" | "medium";
}

const Button: React.FC<Props> = ({
  label,
  color,
  inverted,
  children,
  size = "medium",
  ...buttonProps
}) => {
  const btnSize =
    size == "medium" ? "py-2 px-4 font-medium" : "py-1 px-2 text-xs";

  if (color === "primary") {
    return (
      <PrimaryButton
        label={label}
        size={btnSize}
        inverted={inverted}
        {...buttonProps}
      >
        {children}
      </PrimaryButton>
    );
  }
  return (
    <SecondaryButton
      size={btnSize}
      label={label}
      inverted={inverted}
      {...buttonProps}
    >
      {children}
    </SecondaryButton>
  );
};

export default Button;
