import { InputHTMLAttributes, useEffect, useState } from "react";
import SelectInput, {
  OptionType,
} from "../../../components/common/Inputs/SelectInput";
import { FormikErrors, FormikProps } from "formik";
import { OrderInterface } from "../../../interfaces/orderInterface";
import { AddressesDataInterface } from "../../../interfaces/dealerInterface";

interface Props extends InputHTMLAttributes<HTMLSelectElement> {
  label: string;
  error?: FormikErrors<FormData>;
  dataSrc: AddressesDataInterface[];
  formik: FormikProps<OrderInterface>;
  formKey: keyof OrderInterface;
}

const AddressSelector = ({ formik, label, dataSrc, formKey }: Props) => {
  const [options, setOptions] = useState<OptionType[]>([]);
  useEffect(() => {
    const nOptions: OptionType[] = [];
    dataSrc.forEach((x) => {
      nOptions.push({
        label: `${x.address}, ${x.zipCode}, ${x.city}, ${x.state}`,
        value: x._id,
      });
    });
    setOptions(nOptions);
  }, [dataSrc]);
  return (
    <SelectInput
      label={label}
      options={options}
      {...formik.getFieldProps(formKey)}
      error={
        formik.touched[formKey] &&
        formik.errors[formKey] &&
        Array.isArray(formik.errors[formKey])
          ? (formik.errors[formKey] as string[]).join(", ")
          : (formik.errors[formKey] as string)
      }
    />
  );
};

export default AddressSelector;
