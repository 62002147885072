import { useCallback, useEffect, useState } from "react";
import Checkbox from "../../../components/common/Inputs/Checkbox";
import { SectionDataInterface } from "../../../config/sectionsConfig";
import { ActionInterface } from "../../../config/actionsConfig";
import {
  PermissionsInterface,
  RoleInterface,
} from "../../../interfaces/roleInterface";
import { Formik, FormikProps } from "formik";

interface sectionPropsInterface {
  data: SectionDataInterface;
  sectionKey: keyof PermissionsInterface;
  formik: FormikProps<RoleInterface>;
}

const SectionItem = ({ data, sectionKey, formik }: sectionPropsInterface) => {
  const CheckItem = useCallback(
    (i: string) => {
      const key = i as keyof ActionInterface;
      const isChecked =
        formik.values.permissions[sectionKey] &&
        formik.values.permissions[sectionKey][key]
          ? true
          : false;
      return (
        <Checkbox
          key={`${data.name}-${i}-action`}
          label={`${data.actions[key]} ${data.label}`}
          id={`${data.name}${i}`}
          name={`permissions.${sectionKey}.${key}`}
          onChange={(e) => {
            formik.setFieldValue(
              `permissions.${sectionKey}.${key}`,
              !isChecked
            );
          }}
          checked={isChecked}
        />
      );
    },
    [formik]
  );

  return (
    <div>
      <span>{data.label}</span>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
        {Object.keys(data.actions).map((i) => CheckItem(i))}
      </div>
    </div>
  );
};

export default SectionItem;
