import { ApiResponse } from "../interfaces/apiResponseInterface";
import { RoleInterface } from "../interfaces/roleInterface";
import axiosTeethTab from "./apiCall";

interface RoleApiInterface {
  getRoles: () => Promise<ApiResponse<RoleInterface[]>>;
  saveRoles: (role: RoleInterface) => Promise<ApiResponse<RoleInterface>>;
}

const roleApi: RoleApiInterface = {
  getRoles: async () => {
    const endpoint = "roles";
    return await axiosTeethTab.get<RoleInterface[]>(endpoint);
  },
  saveRoles: async (role) => {
    const endpoint = role._id ? `roles/${role._id}` : "roles";
    return await axiosTeethTab.post<RoleInterface>(endpoint, role);
  },
};

export default roleApi;
