import * as Yup from "yup";

const roleSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Nombre demaciado corto")
    .required("Campo requerido"),
  permissions: Yup.object().shape({
    users: Yup.object().shape({
      create: Yup.boolean(),
      read: Yup.boolean(),
      update: Yup.boolean(),
      delete: Yup.boolean(),
    }),
    roles: Yup.object().shape({
      create: Yup.boolean(),
      read: Yup.boolean(),
      update: Yup.boolean(),
      delete: Yup.boolean(),
    }),
    categories: Yup.object().shape({
      create: Yup.boolean(),
      read: Yup.boolean(),
      update: Yup.boolean(),
      delete: Yup.boolean(),
    }),
    products: Yup.object().shape({
      create: Yup.boolean(),
      read: Yup.boolean(),
      update: Yup.boolean(),
      delete: Yup.boolean(),
    }),
    branches: Yup.object().shape({
      create: Yup.boolean(),
      read: Yup.boolean(),
      update: Yup.boolean(),
      delete: Yup.boolean(),
    }),
    dealers: Yup.object().shape({
      create: Yup.boolean(),
      read: Yup.boolean(),
      update: Yup.boolean(),
      delete: Yup.boolean(),
    }),
    orders: Yup.object().shape({
      create: Yup.boolean(),
      read: Yup.boolean(),
      update: Yup.boolean(),
      delete: Yup.boolean(),
    }),
  }),
});

export default roleSchema;
