import * as Yup from "yup";

const dealerSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Nombre demaciado corto")
    .required("Campo requerido"),
  phoneNumber: Yup.string()
    .min(10, "Número demaciado corto, almenos debe de tener 10 digitos")
    .required("Campo requerido"),
  invoiceData: Yup.object().shape({
    rfc: Yup.string(),
    businessName: Yup.string(),
    cfdi: Yup.string(),
    address: Yup.string(),
    zipCode: Yup.string(),
    country: Yup.string(),
    state: Yup.string(),
    city: Yup.string(),
    email: Yup.string(),
  }),
});

export default dealerSchema;
