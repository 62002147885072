import { useEffect, useState } from "react";
import Button from "../../../components/common/Buttons/Button";
import CustomInput from "../../../components/common/Inputs/CustomInput";
import CustomTable from "../../../components/common/Table/CustomTable";
import {
  AddressesDataInterface,
  DealerInterface,
} from "../../../interfaces/dealerInterface";
import { FormikProps } from "formik";
import Checkbox from "../../../components/common/Inputs/Checkbox";
import { ColumnInterface } from "../../../interfaces/customTableInterface";
const { v4: uuidv4 } = require("uuid");

interface AddressesProps {
  list: AddressesDataInterface[];
  formik: FormikProps<DealerInterface>;
}

interface ErrorsInterface {
  address: Boolean;
  zipCode: Boolean;
  country: Boolean;
  state: Boolean;
  city: Boolean;
}

const columns: ColumnInterface<AddressesDataInterface>[] = [
  {
    key: "address",
    label: "Dirección",
  },
  {
    key: "zipCode",
    label: "CP",
  },
  {
    key: "country",
    label: "País",
  },
  {
    key: "state",
    label: "Estado",
  },
  {
    key: "city",
    label: "Ciudad",
  },
  {
    key: "isFiscalAddresses",
    label: "Dirección Fiscal",
    isBoolean: true,
  },
  {
    key: "active",
    label: "Activo",
    isBoolean: true,
  },
];

const initFormData: AddressesDataInterface = {
  _id: "",
  address: "",
  zipCode: "",
  country: "",
  state: "",
  city: "",
  active: true,
  isFiscalAddresses: false,
};

const initErrors: ErrorsInterface = {
  address: false,
  zipCode: false,
  country: false,
  state: false,
  city: false,
};

const Addresses = ({ list, formik }: AddressesProps) => {
  const [showForm, setShowForm] = useState<Boolean>(false);
  const [formData, setFormData] =
    useState<AddressesDataInterface>(initFormData);
  const [errors, setErrors] = useState<ErrorsInterface>(initErrors);

  useEffect(() => {
    if (showForm && !formData._id) {
      setFormData({ ...initFormData, _id: uuidv4() });
    }
  }, [showForm]);
  const toggleForm = () => {
    setShowForm(!showForm);
    setFormData(initFormData);
  };
  const handleSave = () => {
    if (validateForm()) {
      const updatedAddresses = [...formik.values.addresses];
      const index = updatedAddresses.findIndex((x) => x._id === formData._id);

      if (index !== -1) {
        updatedAddresses[index] = formData; // Update existing data
      } else {
        updatedAddresses.push(formData); // Add new data
      }
      formik.setFieldValue("addresses", updatedAddresses);
      toggleForm();
    }
  };

  const validateForm = (): Boolean => {
    let isValid = true;
    const keys = Object.keys(formData);
    let nErrors = errors;
    keys.map((x) => {
      if (
        x != "isFiscalAddresses" &&
        x != "active" &&
        !formData[x as keyof AddressesDataInterface]
      ) {
        isValid = false;
        nErrors = { ...nErrors, [x as keyof ErrorsInterface]: true };
      }
    });
    if (!isValid) {
      setErrors(nErrors);
    } else {
      setErrors(initErrors);
    }
    console.log(formData);
    return isValid;
  };

  const handleOnChange = (key: string, value: string) => {
    const nFormData = {
      ...formData,
      [key]: value,
    };
    setFormData(nFormData);
  };

  const handleEdit = (address: AddressesDataInterface) => {
    setShowForm(true);
    let addressToEdit = { ...address };
    setFormData(addressToEdit);
  };

  const handleRemove = (id: string) => {
    const updatedAddresses = formik.values.addresses;
    const index = updatedAddresses.findIndex((x) => x._id === id);

    if (index !== -1) {
      updatedAddresses[index] = formData; // Update existing data
    }
    formik.setFieldValue("addresses", updatedAddresses);
  };
  return (
    <div>
      <Button size="small" color="primary" onClick={toggleForm}>
        Nuevo
      </Button>
      {showForm && (
        <>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <CustomInput
              label="Dirección"
              error={errors.address && "Campor requerido"}
              onChange={(e) => {
                handleOnChange("address", e.target.value);
              }}
              value={formData.address}
            />
            <CustomInput
              label="CP"
              error={errors.zipCode && "Campor requerido"}
              onChange={(e) => {
                handleOnChange("zipCode", e.target.value);
              }}
              value={formData.zipCode}
            />
            <CustomInput
              label="País"
              error={errors.country && "Campor requerido"}
              onChange={(e) => {
                handleOnChange("country", e.target.value);
              }}
              value={formData.country}
            />
            <CustomInput
              label="Estado"
              error={errors.state && "Campor requerido"}
              onChange={(e) => {
                handleOnChange("state", e.target.value);
              }}
              value={formData.state}
            />
            <CustomInput
              label="Ciudad"
              error={errors.city && "Campor requerido"}
              onChange={(e) => {
                handleOnChange("city", e.target.value);
              }}
              value={formData.city}
            />
            <Checkbox
              label="Es dirección fiscal?"
              id={"isFiscalAddresses"}
              name="isFiscalAddresses"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  isFiscalAddresses: !formData.isFiscalAddresses,
                });
              }}
              checked={formData.isFiscalAddresses}
            />
          </div>
          <div className="flex justify-center md:justify-end gap-4 mb-2 mt-1 md:mt-auto">
            <Button size="small" color="primary" onClick={handleSave}>
              Guardar
            </Button>
            <Button size="small" color="secondary" onClick={toggleForm}>
              Cancelar
            </Button>
          </div>
        </>
      )}

      <CustomTable
        tableId="addresses-table"
        data={list}
        columns={columns}
        onEdit={handleEdit}
        onDelete={handleRemove}
      />
    </div>
  );
};

export default Addresses;
