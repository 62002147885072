export const ivaValue: number = 0.16;
export const isrValue: number = 0.0125;
export const MoralTaxRegime: string = "moral";
export const FisicaTaxRegime: string = "fisica";
export const ShipmentProductCategoryID: string = "6510c99e77c4129ff118b7a5";

export interface IPaymentMethods {
  cash: string;
  transfer: string;
}

export interface ITaxRegimeOptions {
  fisica: string;
  moral: string;
  regimenActividadesEmpresarialesIngresosPlataformasTecnológicas: string;
  regimenSimplificadoConfianza: string;
  actividadesEmpresarialesProfesionales: string;
}

export interface IPaymentType {
  fullPayment: string;
  deferred: string;
}

export const paymentMethods: IPaymentMethods = {
  cash: "Efectivo",
  transfer: "Transferencia",
};

export const taxRegimeOptions: ITaxRegimeOptions = {
  fisica: "Persona Fisica",
  moral: "Persona Moral",
  regimenActividadesEmpresarialesIngresosPlataformasTecnológicas:
    "Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas",
  regimenSimplificadoConfianza: "Régimen Simplificado de Confianza",
  actividadesEmpresarialesProfesionales:
    "Actividades empresariales y profesionales",
};

export const paymentTypes: IPaymentType = {
  fullPayment: "Pago Único",
  deferred: "Pago por definir",
};
