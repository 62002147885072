import React, { ReactNode, useState } from "react";
import Button from "../Buttons/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { RoleInterface } from "../../../interfaces/roleInterface";
import {
  ColumnInterface,
  ExtraDataInterface,
} from "../../../interfaces/customTableInterface";
import formatToMoney from "../../../utils/formatToMoney";
import { getFullDateFormat } from "../../../utils/dateFormater";
import DownloadPDF from "./CustomActions/DownloadPDF";

interface DataTableProps<T> {
  data: T[];
  columns: ColumnInterface<T>[];
  onEdit?: (data: T, index?: number) => void;
  onDelete?: (id: any) => void;
  tableId: string;
  extraData?: ExtraDataInterface<any>[];
  tfoot?: ReactNode | ReactNode[];
  downloadPDF?: boolean;
  paymentStatus?: boolean;
}

const CustomTable = <T extends {}>({
  tableId,
  data,
  columns,
  onEdit,
  onDelete,
  extraData,
  tfoot,
  downloadPDF,
  paymentStatus,
}: DataTableProps<T>) => {
  const [sortedColumn, setSortedColumn] = useState<keyof T | null>(null);
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const getCellValue = (
    item: T,
    column: {
      key: keyof T;
      isMoney?: boolean;
      isDate?: boolean;
      isBoolean?: boolean;
    }
  ) => {
    if (extraData && extraData.some((x) => x.dataType == column.key)) {
      const srcData = extraData.find((x) => x.dataType == column.key);
      return srcData?.src.find((x) => x._id == item[column.key])?.name;
    } else if (column.isMoney) {
      return formatToMoney(item[column.key]);
    } else if (column.isDate) {
      return getFullDateFormat(item[column.key] as string);
    } else if (column.isBoolean) {
      return item[column.key] ? "Si" : "No";
    }
    return item[column.key];
  };

  const handleSort = (columnKey: keyof T) => {
    if (columnKey === sortedColumn) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortedColumn(columnKey);
      setSortDirection("asc");
    }
  };

  const sortedData = [...data].sort((a, b) => {
    if (sortedColumn !== null) {
      const aValue = a[sortedColumn];
      const bValue = b[sortedColumn];
      if (aValue < bValue) return sortDirection === "asc" ? -1 : 1;
      if (aValue > bValue) return sortDirection === "asc" ? 1 : -1;
    }
    return 0;
  });

  const handleOnClick = (data: T, index: number) => {
    if (tableId === "addresses-table") {
      onEdit!(data, index);
    } else {
      onEdit!(data);
    }
  };

  const getPaymentStatus = (total?: number, totalPayed?: number): string => {
    let status = "Pendiente de pago";
    if (totalPayed) {
      if (total == totalPayed) {
        status = "Pagado";
      } else {
        status = "Por saldar";
      }
    }
    return status;
  };

  return (
    <div className="border rounded-lg overflow-hidden mt-2 overflow-x-auto">
      <table className="min-w-full">
        <thead className="bg-gray-100">
          <tr>
            {columns.map((column) => (
              <React.Fragment key={`header-${tableId}-${column.key as string}`}>
                <th
                  className="py-2 px-4 border-b cursor-pointer"
                  onClick={() => handleSort(column.key)}
                >
                  {column.label}{" "}
                  {sortedColumn === column.key && (
                    <span>{sortDirection === "asc" ? "↑" : "↓"}</span>
                  )}
                </th>
                {paymentStatus && column.key == "status" && (
                  <th className="py-2 px-4 border-b">Estatus de pago</th>
                )}
              </React.Fragment>
            ))}
            <th>Opciones</th>
          </tr>
        </thead>
        <tbody>
          {sortedData.map((item, index) => (
            <tr key={`columns-${tableId}-${index}`}>
              {columns.map((column) => (
                <React.Fragment key={`${tableId}-${column.key as string}`}>
                  <td key={column.key as string} className="py-2 px-4 border-b">
                    <>{getCellValue(item, column)}</>
                  </td>
                  {paymentStatus && column.key == "status" && (
                    <td
                      key={`${column.key as string}-status-pago`}
                      className="py-2 px-4 border-b"
                    >
                      {getPaymentStatus(
                        (item as any).total,
                        (item as any).totalPayed
                      )}
                    </td>
                  )}
                </React.Fragment>
              ))}
              <td className="py-2 px-4 border-b">
                <div className="flex gap-2 items-end justify-start">
                  {onEdit && (
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => handleOnClick(item, index)}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </Button>
                  )}
                  {downloadPDF && (
                    <DownloadPDF id={item["_id" as keyof T] as string} />
                  )}
                  {onDelete && (
                    <Button
                      size="small"
                      color="secondary"
                      onClick={() => onDelete(item)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
        {tfoot}
      </table>
    </div>
  );
};

export default CustomTable;
