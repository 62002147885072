import axios, { AxiosRequestConfig } from "axios";
const baseUrl = process.env.REACT_APP_APIURL;

const axiosTeethTab = axios.create({
  baseURL: baseUrl,
});

export const setAuthToken = (token: string | null) => {
  if (token) {
    axiosTeethTab.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axiosTeethTab.defaults.headers.common["Authorization"];
  }
};

export default axiosTeethTab;
