import { isrValue, ivaValue } from "../constants/sistem";
import {
  PaymentsInterface,
  ProductOrderInterface,
} from "../interfaces/orderInterface";

export interface CalcTotalPriceResInterface {
  subTotal: number;
  iva: number;
  isr: number;
  total: number;
}

export const calcTotalOrderPrice = (
  products: ProductOrderInterface[],
  isTaxRegimeMoral: boolean
): CalcTotalPriceResInterface => {
  let subTotal = 0,
    iva = 0,
    isr = 0,
    total = 0;
  if (products) {
    products.forEach((x) => {
      subTotal += x.total;
    });
  }
  iva = subTotal * ivaValue;
  if (isTaxRegimeMoral) {
    isr = subTotal * isrValue;
  }
  total = parseFloat((subTotal + iva - isr).toFixed(2));
  return {
    subTotal,
    iva,
    isr,
    total,
  };
};

export const getDiscountByTotalOrder = (orderSubTotal: number): number => {
  let discount: number = 0;
  if (orderSubTotal >= 3300 && orderSubTotal <= 5500) {
    discount = 6;
  } else if (orderSubTotal > 5500 && orderSubTotal <= 10500) {
    discount = 10;
  } else if (orderSubTotal > 10500 && orderSubTotal <= 16500) {
    discount = 15;
  } else if (orderSubTotal > 16500) {
    discount = 18;
  }
  return discount;
};

export const getProductTotalCalc = (
  productSubTotal: number,
  discount: number
): number => {
  if (!discount) {
    return productSubTotal;
  }
  const totalToDiscount = (productSubTotal * discount) / 100;
  const total = productSubTotal - totalToDiscount;
  return total;
};

export const getSubTotalProductORder = (products: ProductOrderInterface[]) => {
  return products.reduce(
    (sum, product) => sum + product.totalWithoutDiscount,
    0
  );
};

export const getBalance = (total: number, totalPayed?: number) => {
  if (!totalPayed) {
    return total;
  }
  return total - totalPayed;
};

export const getTotalPayed = (payments: PaymentsInterface[]) => {
  return payments.reduce((sum, product) => sum + product?.amount!, 0);
};
