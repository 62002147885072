import { ApiResponse } from "../interfaces/apiResponseInterface";
import { CategoryInterface } from "../interfaces/categoryInterface";
import axiosTeethTab from "./apiCall";

interface CategoryApiInterface {
  getCategories: () => Promise<ApiResponse<CategoryInterface[]>>;
  saveCategory: (
    category: CategoryInterface
  ) => Promise<ApiResponse<CategoryInterface>>;
}

const categoryApi: CategoryApiInterface = {
  getCategories: async () => {
    const endpoint = "categories";
    return await axiosTeethTab.get<CategoryInterface[]>(endpoint);
  },
  saveCategory: async (category) => {
    const endpoint = category._id ? `categories/${category._id}` : "categories";
    return await axiosTeethTab.post<CategoryInterface>(endpoint, category);
  },
};

export default categoryApi;
