import React from "react";
import { FaBars } from "react-icons/fa";
import UserToolbar from "./UserToolbar";

interface TopbarInterface {
  toggle(): any;
  logout?: () => any;
}

const Topbar = ({ toggle, logout }: TopbarInterface) => {
  return (
    <div className="bg-white shadow-md h-12 pt-2 md:pt-1 pb-1 px-1 mt-0 h-auto fixed w-full md:w-[calc(100vw-12rem)] z-20 top-0">
      <div className="flex justify-between md:justify-end items-center relative">
        <FaBars className="ml-2 md:hidden" onClick={toggle} />
        <UserToolbar logout={logout} />
      </div>
    </div>
  );
};

export default Topbar;
