import React, { useEffect, useState } from "react";
import Button from "../../components/common/Buttons/Button";
import Modal from "../../components/common/Modal";
import Title from "../../components/common/Title";
import { UserInterface } from "../../interfaces/userInterface";
import CustomTable from "../../components/common/Table/CustomTable";
import { FormikHelpers, useFormik } from "formik";
import CustomInput from "../../components/common/Inputs/CustomInput";
import useUserStore from "../../stores/useUserStore";
import useRoleStore from "../../stores/useRoleStore";
import SelectInput, {
  OptionType,
} from "../../components/common/Inputs/SelectInput";
import { RoleInterface } from "../../interfaces/roleInterface";
import {
  ColumnInterface,
  ExtraDataInterface,
} from "../../interfaces/customTableInterface";
import ChangePassword from "./components/ChangePassword";
import userSchema from "../../schemas/userSchema";
import { UNAUTHORIZED_ERROR } from "../../constants/apiErrors";
import { useIsAuth } from "../../utils/useAuth";
import Loading from "../../components/Loading";

const formDataInit: UserInterface = {
  name: "",
  lastName: "",
  userName: "",
  password: "",
  active: true,
  roleId: "",
};

const columns: ColumnInterface<UserInterface>[] = [
  {
    key: "name",
    label: "Nombre",
  },
  {
    key: "lastName",
    label: "Apellido",
  },
  {
    key: "userName",
    label: "Nombre de usuario",
  },
  {
    key: "roleId",
    label: "Rol",
  },
];

interface ChangePasswordInterface {
  show: boolean;
  oldPassword: string;
}

const changePasswordInterfaceInit: ChangePasswordInterface = {
  show: false,
  oldPassword: "",
};

const UserPage = () => {
  const { logout } = useIsAuth();
  const { loading, users, getData, saveData, error, resetError } =
    useUserStore();
  const { roles, getData: getRolesData } = useRoleStore();
  const [rolesOptions, setRolesOptions] = useState<OptionType[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [newPassword, setNewPassword] = useState<ChangePasswordInterface>(
    changePasswordInterfaceInit
  );

  const extraData: ExtraDataInterface<RoleInterface>[] = [
    {
      src: roles,
      dataType: "roleId",
    },
  ];

  useEffect(() => {
    getData();
    getRolesData();
  }, []);

  useEffect(() => {
    if (error === UNAUTHORIZED_ERROR) {
      resetError();
      logout();
    }
  }, [error]);

  useEffect(() => {
    const nOptions: OptionType[] = roles.map(({ _id, name }) => {
      return {
        value: _id ? _id : "",
        label: name,
      };
    });
    setRolesOptions(nOptions);
  }, [roles]);

  const formik = useFormik<UserInterface>({
    initialValues: formDataInit,
    validationSchema: userSchema,
    onSubmit: (
      values: UserInterface,
      formikHelpers: FormikHelpers<UserInterface>
    ) => {
      saveData(values);
      formikHelpers.setSubmitting(false); // You can perform additional actions here if needed
      formikHelpers.resetForm();
      handleOnClose();
    },
  });

  const handleOnClose = () => {
    setShowModal(false);
    formik.resetForm();
    setNewPassword(changePasswordInterfaceInit);
  };

  const handleEdit = (user: UserInterface) => {
    setShowModal(true);
    setNewPassword({
      show: false,
      oldPassword: user.password,
    });
    formik.setValues(user);
  };
  const showPasswordField =
    (formik.values._id && newPassword.show) || !formik.values._id;

  return (
    <div>
      <Title title="Users" />
      <div className="flex justify-end">
        <Button
          size="medium"
          onClick={() => setShowModal(true)}
          color="primary"
        >
          Nuevo
        </Button>
      </div>
      {loading ? (
        <Loading />
      ) : (
        <CustomTable
          tableId="user-table"
          data={users}
          columns={columns}
          onEdit={handleEdit}
          extraData={extraData}
        />
      )}
      <Modal
        show={showModal}
        close={handleOnClose}
        submit={formik.handleSubmit}
      >
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <CustomInput
            label="Nombre"
            error={
              formik.touched.name &&
              formik.errors.name &&
              Array.isArray(formik.errors.name)
                ? formik.errors.name.join(", ")
                : formik.errors.name
            }
            {...formik.getFieldProps("name")}
            onChange={(e) => {
              formik.handleChange(e);
            }}
          />
          <CustomInput
            label="Apellidos"
            error={
              formik.touched.lastName &&
              formik.errors.lastName &&
              Array.isArray(formik.errors.lastName)
                ? formik.errors.lastName.join(", ")
                : formik.errors.lastName
            }
            {...formik.getFieldProps("lastName")}
            onChange={(e) => {
              formik.handleChange(e);
            }}
          />
          <CustomInput
            label="Nombre de Usuario"
            error={
              formik.touched.userName &&
              formik.errors.userName &&
              Array.isArray(formik.errors.userName)
                ? formik.errors.userName.join(", ")
                : formik.errors.userName
            }
            {...formik.getFieldProps("userName")}
            onChange={(e) => {
              formik.handleChange(e);
            }}
          />
          {showPasswordField ? (
            <CustomInput
              label="Contraseña"
              type="password"
              error={
                formik.touched.password &&
                formik.errors.password &&
                Array.isArray(formik.errors.password)
                  ? formik.errors.password.join(", ")
                  : formik.errors.password
              }
              {...formik.getFieldProps("password")}
              onChange={(e) => {
                formik.handleChange(e);
              }}
            />
          ) : (
            <ChangePassword
              error={
                formik.touched.password &&
                formik.errors.password &&
                Array.isArray(formik.errors.password)
                  ? formik.errors.password.join(", ")
                  : formik.errors.password
              }
              {...formik.getFieldProps("password")}
              onChange={(e) => {
                formik.handleChange(e);
              }}
              formik={formik}
              oldPassword={newPassword.oldPassword}
            />
          )}

          <SelectInput
            label="Roles"
            options={rolesOptions}
            error={
              formik.touched.roleId &&
              formik.errors.roleId &&
              Array.isArray(formik.errors.roleId)
                ? formik.errors.roleId.join(", ")
                : formik.errors.roleId
            }
            {...formik.getFieldProps("roleId")}
            onChange={(e) => {
              formik.handleChange(e);
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default UserPage;
