import { FormikErrors } from "formik";
import React, { InputHTMLAttributes } from "react";
import ReactDatePicker from "react-datepicker";
import { IconType } from "react-icons/lib";

interface Props {
  label?: string;
  error?: FormikErrors<FormData>;
  customClass?: string;
  value?: Date;
  handleOnChange: (date: Date) => void;
}

const CustomDatePicker: React.FC<Props> = ({
  label,
  error,
  customClass,
  value,
  handleOnChange,
}) => {
  return (
    <div>
      <label className="block font-medium">{label}</label>
      <div className="relative">
        <ReactDatePicker
          dateFormat={"dd/MM/yyyy"}
          className={`border-slate-300 rounded p-2 border w-full h-11 ${
            error ? "border-red-500" : ""
          } ${customClass}`}
          selected={value}
          onChange={handleOnChange}
        />
      </div>
      {error && <div className="error text-red-500">{<>{error}</>}</div>}
    </div>
  );
};

export default CustomDatePicker;
