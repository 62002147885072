import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import orderApi from "../../services/orderApi";
import Loading from "../../components/Loading";
import { OrderInterface } from "../../interfaces/orderInterface";
import base64toBlob from "../../utils/base64toBlob";
import Button from "../../components/common/Buttons/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import useCheckMobileScreen from "../../hooks/useCheckMobileScreen";

const Invoice = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [pdfData, setPdfData] = useState<string | null>(null);
  const [fileName, setFileName] = useState<string>("");
  const isMobile = useCheckMobileScreen();

  useEffect(() => {
    const loadInvoice = async () => {
      try {
        const res = await orderApi.getInvoice(id!);
        const pdfBlob = base64toBlob(res.data?.file!, "application/pdf");
        const pdfUrl = URL.createObjectURL(pdfBlob);
        setFileName(res.data?.fileName!);
        setPdfData(pdfUrl);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching PDF:", error);
      }
    };
    if (id) {
      loadInvoice();
    }
  }, []);

  const onDownload = () => {
    if (pdfData) {
      const downloadLink = document.createElement("a");
      downloadLink.href = pdfData;
      downloadLink.download = fileName; // Specify the desired file name
      downloadLink.click();
    }
  };

  return (
    <div>
      {!loading && pdfData ? (
        <div>
          {isMobile ? (
            <div className="flex justify-center items-center h-screen">
              <Button size="small" color="primary" onClick={onDownload}>
                Descargar PDF
                <FontAwesomeIcon icon={faDownload} />
              </Button>
            </div>
          ) : (
            <embed src={pdfData} className="w-full h-screen" />
          )}
        </div>
      ) : (
        <Loading fullScreen={true} />
      )}
    </div>
  );
};

export default Invoice;
