import { IApiPagination } from "../interfaces/IPagination";
import {
  ApiOrderResponse,
  ApiResponse,
} from "../interfaces/apiResponseInterface";
import { IOrderFilter, OrderInterface } from "../interfaces/orderInterface";
import axiosTeethTab from "./apiCall";

interface IInvoice {
  file: string;
  fileName: string;
}

interface OrderApiInterface {
  getOrders: (
    filter: IOrderFilter,
    pagination: IApiPagination
  ) => Promise<ApiResponse<ApiOrderResponse<OrderInterface>>>;
  saveOrders: (order: OrderInterface) => Promise<ApiResponse<OrderInterface>>;
  getLastNoOrder: () => Promise<ApiResponse<number>>;
  getInvoice: (orderId: string) => Promise<ApiResponse<IInvoice>>;
  getOrder: (orderId: string) => Promise<ApiResponse<OrderInterface>>;
}

const orderApi: OrderApiInterface = {
  getOrders: async (filter: IOrderFilter, pagination: IApiPagination) => {
    const endpoint = "orders";
    return await axiosTeethTab.get<ApiOrderResponse<OrderInterface>>(endpoint, {
      params: {
        filter,
        pagination,
      },
    });
  },
  saveOrders: async (order) => {
    const endpoint = order._id ? `orders/${order._id}` : "orders";
    return await axiosTeethTab.post<OrderInterface>(endpoint, order);
  },
  getLastNoOrder: async () => {
    const endpoint = "orders/next";
    return await axiosTeethTab.get<number>(endpoint);
  },
  getOrder: async (orderId: string) => {
    const endpoint = `orders/${orderId}`;
    return await axiosTeethTab.get<OrderInterface>(endpoint);
  },
  getInvoice: async (orderId: string) => {
    const endpoint = `orders/${orderId}/invoice`;
    return await axiosTeethTab.get(endpoint, {});
  },
};

export default orderApi;
