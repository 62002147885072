export interface ActionInterface {
  create?: string;
  read?: string;
  update?: string;
  delete?: string;
}

export const generalActions: ActionInterface = {
  create: "Crear",
  read: "Ver",
  update: "Actualizar",
  delete: "Eliminar",
};
