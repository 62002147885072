import { ActionInterface, generalActions } from "./actionsConfig";

export interface SectionDataInterface {
  name: string;
  label: string;
  actions: ActionInterface;
}

export interface SectionInterface {
  users: SectionDataInterface;
  roles: SectionDataInterface;
  categories: SectionDataInterface;
  products: SectionDataInterface;
  branches: SectionDataInterface;
  dealers: SectionDataInterface;
  orders: SectionDataInterface;
}

const sisSections: SectionInterface = {
  users: {
    name: "users",
    label: "Usuarios",
    actions: generalActions,
  },
  roles: {
    name: "roles",
    label: "Roles",
    actions: generalActions,
  },
  categories: {
    name: "categories",
    label: "Categorías",
    actions: generalActions,
  },
  products: {
    name: "products",
    label: "Productos",
    actions: generalActions,
  },
  branches: {
    name: "branches",
    label: "Sucursales",
    actions: generalActions,
  },
  dealers: {
    name: "dealers",
    label: "Distribuidores",
    actions: generalActions,
  },
  orders: {
    name: "orders",
    label: "Pedidos",
    actions: generalActions,
  },
};

export default sisSections;
