import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../../../components/common/Buttons/Button";
import { ProductOrderInterface } from "../../../interfaces/orderInterface";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import formatToMoney from "../../../utils/formatToMoney";

interface Props {
  onEdit?: (product: ProductOrderInterface) => void;
  onDelete?: (product: ProductOrderInterface) => void;
  products: ProductOrderInterface[];
}

const ProductsTabe = ({ products, onEdit, onDelete }: Props) => {
  return (
    <div className="border rounded-lg overflow-hidden mt-2 overflow-x-auto">
      <table className="min-w-full">
        <thead className="bg-gray-100">
          <tr>
            <th className="py-2 px-4 border-b cursor-pointer">Product</th>
            <th className="py-2 px-4 border-b cursor-pointer">Precio</th>
            <th className="py-2 px-4 border-b cursor-pointer">Cantidad</th>
            <th className="py-2 px-4 border-b cursor-pointer">Total</th>
            <th className="py-2 px-4 border-b cursor-pointer">Opciones</th>
          </tr>
        </thead>
        <tbody>
          {products.map((item, index) => (
            <tr key={`columns-product-${index}`}>
              <td className="py-2 px-4 border-b">{item.name}</td>
              <td className="py-2 px-4 border-b">
                {formatToMoney(item.price)}
              </td>
              <td className="py-2 px-4 border-b">{item.quantity}</td>
              <td className="py-2 px-4 border-b">
                {formatToMoney(item.total)}
              </td>
              <td className="py-2 px-4 border-b">
                <div className="flex gap-2 items-end justify-start">
                  {onEdit && (
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => onEdit(item)}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </Button>
                  )}
                  {onDelete && (
                    <Button
                      size="small"
                      color="secondary"
                      onClick={() => onDelete(item)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ProductsTabe;
