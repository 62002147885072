import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

interface Props {
  fullScreen?: boolean;
}

const Loading = ({ fullScreen }: Props) => {
  const customClass = fullScreen ? "h-screen" : "";
  return (
    <div className={`${customClass} w-full flex justify-center items-center`}>
      <div className="text-center">
        <FontAwesomeIcon
          className="text-5xl"
          color="#3bb6f4"
          spin
          icon={faSpinner}
        />
        <p className="mt-5">Cargando</p>
      </div>
    </div>
  );
};

export default Loading;
