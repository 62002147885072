import React, { ReactElement } from "react";
import { Link } from "react-router-dom";

interface LinkInterface {
  to: string;
  label: string;
  groupe?: string;
  active?: boolean;
  icon?: ReactElement;
}

const SidebarLink = ({ to, label, groupe, icon, active }: LinkInterface) => {
  return (
    <Link
      to={to}
      className={`group flex items-center transition-all block py-3 pl-1 align-middle text-white text-white border-b-2 border-slate-400 hover:border-sky-500 ${
        active && "border-b-2 border-sky-500"
      }`}
    >
      {icon && (
        <div
          className={`group-hover:text-sky-500 ${
            active && "text-b-2 border-sky-500"
          }`}
        >
          {icon}
        </div>
      )}
      <span
        className={`${
          icon && "ml-2"
        } text-base text-white font-bold inline-block`}
      >
        {label}
      </span>
    </Link>
  );
};

export default SidebarLink;
