import React, { useEffect, useRef, useState } from "react";
import Button from "../../components/common/Buttons/Button";
import CustomInput from "../../components/common/Inputs/CustomInput";
import Modal from "../../components/common/Modal";
import Title from "../../components/common/Title";
import {
  ActionInterface,
  PermissionsInterface,
  RoleInterface,
} from "../../interfaces/roleInterface";
import { FormikHelpers, useFormik } from "formik";
import Sections from "./components/Sections";
import useRoleStore from "../../stores/useRoleStore";
import CustomError from "../../components/common/CustomError";
import CustomTable from "../../components/common/Table/CustomTable";
import { ColumnInterface } from "../../interfaces/customTableInterface";
import roleSchema from "../../schemas/roleSchema";
import { UNAUTHORIZED_ERROR } from "../../constants/apiErrors";
import { useIsAuth } from "../../utils/useAuth";
import Loading from "../../components/Loading";

const formDataInit: RoleInterface = {
  name: "",
  permissions: {
    users: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    roles: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    categories: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    products: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    branches: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    dealers: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    orders: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
  },
};

const columns: ColumnInterface<RoleInterface>[] = [
  {
    key: "name",
    label: "Nombre",
  },
];

const RolesPage = () => {
  const { logout } = useIsAuth();
  const { loading, roles, getData, saveData, error, resetError } =
    useRoleStore();
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (error === UNAUTHORIZED_ERROR) {
      resetError();
      logout();
    }
  }, [error]);

  const formik = useFormik<RoleInterface>({
    initialValues: formDataInit,
    validationSchema: roleSchema,
    onSubmit: (
      values: RoleInterface,
      formikHelpers: FormikHelpers<RoleInterface>
    ) => {
      saveData(values);
      formikHelpers.setSubmitting(false); // You can perform additional actions here if needed
      formikHelpers.resetForm();
      handleOnClose();
    },
  });

  const handleOnClose = () => {
    setShowModal(false);
    formik.resetForm();
  };

  const handleEdit = (role: RoleInterface) => {
    setShowModal(true);
    formik.setValues(role);
  };
  return (
    <div>
      <Title title="Roles" />
      <div className="flex justify-end">
        <Button
          size="medium"
          onClick={() => setShowModal(true)}
          color="primary"
        >
          Nuevo
        </Button>
      </div>
      {loading ? (
        <Loading />
      ) : (
        <CustomTable
          tableId="roles-table"
          data={roles}
          columns={columns}
          onEdit={handleEdit}
        />
      )}

      <Modal
        show={showModal}
        close={handleOnClose}
        submit={formik.handleSubmit}
      >
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <CustomInput
            label="Role"
            error={
              formik.touched.name &&
              formik.errors.name &&
              Array.isArray(formik.errors.name)
                ? formik.errors.name.join(", ")
                : formik.errors.name
            }
            {...formik.getFieldProps("name")}
            onChange={(e) => {
              formik.handleChange(e);
            }}
          />
        </div>
        <Sections formik={formik} />
        {formik.submitCount != 0 &&
        !Object.keys(formik.values.permissions).some((category) =>
          Object.keys(
            formik.values.permissions[category as keyof PermissionsInterface]
          ).some(
            (permission) =>
              formik.values.permissions[category as keyof PermissionsInterface][
                permission as keyof ActionInterface
              ]
          )
        ) ? (
          <CustomError error="Selecciona almenos un permiso" />
        ) : null}
      </Modal>
    </div>
  );
};

export default RolesPage;
