import { create } from "zustand";
import { UserInterface } from "../interfaces/userInterface";
import userApi from "../services/usersApi";
import { errorNotification, successNotification } from "../utils/notification";

const usersInit: UserInterface[] = [];

interface UserStoreInterface {
  users: UserInterface[];
  loading: boolean;
  error: string;
  resetError: () => void;
  getData: () => void;
  saveData: (role: UserInterface) => void;
}

const useUserStore = create<UserStoreInterface>((set) => ({
  users: usersInit,
  loading: false,
  error: "",
  resetError: () => {
    set({ error: "" });
  },
  getData: async () => {
    set({ loading: true, error: "" });
    try {
      const response = await userApi.getUsers();
      if (response.ok && response.data) {
        const { data } = response;
        set({ users: data, loading: false });
      } else {
        set({ loading: false });
      }
    } catch (error) {
      set({ loading: false });
    }
  },
  saveData: async (user: UserInterface) => {
    try {
      set({ loading: true, error: "" });
      const response = await userApi.saveUser(user);
      // Pendiente ver mejores practicas de backend para guardar y paginar contenido
      if (response.ok && response.data) {
        const { data } = response; // This line retrieves the data from the response object
        set({ loading: false, error: "" });
        successNotification("Datos guardados con éxito!");
        await useUserStore.getState().getData();
      } else {
        set({ loading: false, error: response.problem as string });
        errorNotification(
          "Oops! algo salió mal, inténtalo de nuevo más tarde."
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      errorNotification("Oops! algo salió mal, inténtalo de nuevo más tarde.");
      set({ loading: false });
    }
  },
}));

export default useUserStore;
