import { FormikProps } from "formik";
import {
  OrderInterface,
  PaymentsInterface,
} from "../../../interfaces/orderInterface";
import SelectInput from "../../../components/common/Inputs/SelectInput";
import { paymentMethodsForm } from "../../../constants/paymentMethods";
import CustomInput from "../../../components/common/Inputs/CustomInput";
import { useEffect, useState } from "react";
import Button from "../../../components/common/Buttons/Button";
import { FaRegTimesCircle, FaSave } from "react-icons/fa";
import { FaPlus } from "react-icons/fa6";
import PaymentsTable from "./PaymentsTable";
import "react-datepicker/dist/react-datepicker.css";
import CustomDatePicker from "../../../components/common/Inputs/CustomDatePicket";
import formatToMoney from "../../../utils/formatToMoney";
import { getBalance, getTotalPayed } from "../../../utils/calcTotalPrice";
import { stringToDate } from "../../../utils/dateFormated";
const { v4: uuidv4 } = require("uuid");

interface Props {
  formik: FormikProps<OrderInterface>; // Define the formik prop
}

interface PaymentErrors {
  amount: string;
  paymentDate: string;
  paymentMethod: string;
}
const paymentInit: PaymentsInterface = {
  amount: undefined,
  paymentDate: undefined,
  paymentMethod: "",
};

const paymentErrorsInit: PaymentErrors = {
  amount: "",
  paymentMethod: "",
  paymentDate: "",
};

const Payments = ({ formik }: Props) => {
  const [payment, setPayment] = useState<PaymentsInterface>(paymentInit);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [errors, setErrors] = useState<PaymentErrors>(paymentErrorsInit);

  useEffect(() => {
    if (formik.values.payments) {
      formik.setFieldValue("totalPayed", getTotalPayed(formik.values.payments));
    } else {
      formik.setFieldValue("totalPayed", 0);
    }
  }, [formik.values.payments]);

  const handleOnChange = (
    key: string,
    value: string | number | Date | null
  ) => {
    setPayment((prevPayment) => ({
      ...prevPayment,
      [key as keyof PaymentsInterface]: value,
    }));
  };

  const savePayment = () => {
    if (formValidation()) {
      let nPayment: PaymentsInterface = payment!;
      if (!isEditing) {
        nPayment = { ...nPayment, _id: uuidv4() };
      }

      const nList = isEditing
        ? formik.values?.payments?.map((x) =>
            x._id === nPayment?._id ? nPayment : x
          )
        : formik.values?.payments
        ? [...formik.values?.payments, nPayment]
        : [nPayment];

      formik.setFieldValue("payments", nList);
      clearForm();
    }
  };

  const clearForm = () => {
    setPayment(paymentInit);
    setIsEditing(false);
    setErrors(paymentErrorsInit);
  };

  const handleEdit = (payment: PaymentsInterface) => {
    const paymentDate = stringToDate(payment?.paymentDate!);
    setPayment({ ...payment, paymentDate });
    setIsEditing(true);
  };

  const handleDelete = (payment: PaymentsInterface) => {
    const nList = formik.values?.payments?.filter((x) => x._id != payment._id);
    formik.setFieldValue("payments", nList);
  };

  const formValidation = (): boolean => {
    const paymentErrors: PaymentErrors = {
      amount: "",
      paymentMethod: "",
      paymentDate: "",
    };
    let isValid = true;
    const keys: Array<keyof PaymentsInterface> = [
      "paymentMethod",
      "amount",
      "paymentDate",
    ];
    keys.forEach((x) => {
      if (x == "amount" && !formik.values.products) {
        paymentErrors.amount = "No se han agregado productos";
        isValid = false;
      } else if (x == "amount" && formik.values.totalPayed) {
        if (!payment.amount) {
          paymentErrors[x as keyof PaymentErrors] = "Campo requerido";
          isValid = false;
        } else {
          const totalPayed = isEditing
            ? formik.values.totalPayed - payment.amount
            : formik.values.totalPayed;
          if (totalPayed + payment.amount > formik.values.total) {
            paymentErrors[x as keyof PaymentErrors] =
              "El monto no puede exceder el total del adeudo";
            isValid = false;
          }
        }
      } else if (!payment[x]) {
        paymentErrors[x as keyof PaymentErrors] = "Campo requerido";
        isValid = false;
      }
    });
    setErrors(paymentErrors);
    return isValid;
  };
  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-2">
        <SelectInput
          label="Método de Pago"
          options={paymentMethodsForm}
          value={payment?.paymentMethod ? payment?.paymentMethod : ""}
          onChange={(e) => handleOnChange("paymentMethod", e.target.value)}
          error={errors["paymentMethod" as keyof PaymentErrors]}
        />
        <CustomInput
          label="Monto a Pagar"
          type="number"
          value={payment?.amount}
          onChange={(e) => handleOnChange("amount", parseFloat(e.target.value))}
          error={errors["amount" as keyof PaymentErrors]}
        />
        <CustomDatePicker
          label="Fecha de Pago"
          value={payment?.paymentDate}
          handleOnChange={(date) => handleOnChange("paymentDate", date)}
          error={errors["paymentDate" as keyof PaymentErrors]}
        />

        <div className="col-span-1 mt-4">
          <Button
            size="medium"
            color="primary"
            className="inline-block mt-2 h-11"
            onClick={savePayment}
          >
            {isEditing ? <FaSave /> : <FaPlus />}
          </Button>
          {isEditing && (
            <Button
              size="medium"
              color="secondary"
              className="inline-block mt-2 ml-2 h-11"
              onClick={clearForm}
            >
              <FaRegTimesCircle />
            </Button>
          )}
        </div>
      </div>
      <PaymentsTable
        payments={formik.values.payments}
        onEdit={handleEdit}
        onDelete={handleDelete}
      />
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-2">
        <div>
          <p className="font-bold">Total Por Pagar</p>
          <p>{formatToMoney(formik.values.total)}</p>
        </div>
        <div>
          <p className="font-bold">Total Pagado</p>
          <p>{formatToMoney(formik.values.totalPayed)}</p>
        </div>
        <div>
          <p className="font-bold">Saldo</p>
          <p>
            {formatToMoney(
              getBalance(formik.values.total, formik.values?.totalPayed)
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Payments;
