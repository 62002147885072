import { ApiResponse } from "../interfaces/apiResponseInterface";
import { AuthInterface, LoginInterface } from "../interfaces/authInterface";
import axiosTeethTab from "./apiCall";

interface UserApiInterface {
  auth: (data: LoginInterface) => Promise<ApiResponse<AuthInterface>>;
}

const authApi: UserApiInterface = {
  auth: async (data: LoginInterface) => {
    const endpoint = "auth";
    return await axiosTeethTab.post<AuthInterface>(endpoint, data);
  },
};

export default authApi;
