import { FormikErrors } from "formik";
import React, { InputHTMLAttributes } from "react";
import { IconType } from "react-icons/lib";

interface Props extends InputHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  error?: FormikErrors<FormData>;
  inputRef?: React.RefObject<HTMLInputElement>;
  customClass?: string;
}

const TextArea: React.FC<Props> = ({
  label,
  error,
  inputRef,
  customClass,
  ...inputProps
}) => {
  return (
    <div>
      <label className="block font-medium">{label}</label>
      <div className="relative">
        <textarea
          className={`border-slate-300 rounded p-2 border w-full ${
            error ? "border-red-500" : ""
          } ${customClass}`}
          {...inputProps}
        />
      </div>
      {error && <div className="error text-red-500">{<>{error}</>}</div>}
    </div>
  );
};

export default TextArea;
