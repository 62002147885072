interface PaymentTypeInterface {
  value: string;
  label: string;
}

const paymentTypes: PaymentTypeInterface[] = [
  {
    value: "fullPayment",
    label: "Pago Único",
  },
  {
    value: "deferred",
    label: "Pago en parcialidades o diferido",
  },
  {
    value: "paymentComplement",
    label: "Complemento de pago",
  },
];

export default paymentTypes;
