import React, { useEffect, useState } from "react";
import {
  FaUsers,
  FaUserLock,
  FaBoxesStacked,
  FaDollarSign,
  FaFolderTree,
} from "react-icons/fa6";
import { FaTimes, FaStoreAlt, FaClipboardList } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import RoutesInterface from "../../interfaces/routesInterface";
import SidebarLink from "./SidebarLink";
import ls, { get, set } from "local-storage";
import { AuthInterface } from "../../interfaces/authInterface";
import { PermissionsInterface } from "../../interfaces/roleInterface";

interface SideBarInterface {
  open: boolean;
  toggle(): any;
}

const Sidebar = ({ open, toggle }: SideBarInterface) => {
  const location = useLocation();
  const userData = get<AuthInterface>("userData");

  useEffect(() => {
    //console.log("location", location)
  }, [location]);

  const checkIfActive = (routeName: string) => {
    return location.pathname.includes(routeName);
  };

  const routes: RoutesInterface[] = [
    {
      route: "users",
      label: "Usuarios",
    },
    {
      route: "roles",
      label: "Roles",
    },
    {
      route: "categories",
      label: "Categorías",
    },
    {
      route: "products",
      label: "Productos",
    },
    {
      route: "dealers",
      label: "Distribuidores",
    },
    /*{
      route: "branches",
      label: "Sucursales",
    },*/
    {
      route: "orders",
      label: "Pedidos",
    },
  ];

  const getLinkIcon = (route: string, isActive: boolean) => {
    switch (route) {
      case "roles":
        return (
          <FaUserLock className={isActive ? "text-sky-500" : "text-white"} />
        );
      case "products":
        return (
          <FaBoxesStacked
            className={isActive ? "text-sky-500" : "text-white"}
          />
        );
      case "categories":
        return (
          <FaFolderTree className={isActive ? "text-sky-500" : "text-white"} />
        );
      case "branches":
        return (
          <FaStoreAlt className={isActive ? "text-sky-500" : "text-white"} />
        );
      case "dealers":
        return (
          <FaClipboardList
            className={isActive ? "text-sky-500" : "text-white"}
          />
        );
      case "orders":
        return (
          <FaDollarSign className={isActive ? "text-sky-500" : "text-white"} />
        );
      default:
        return <FaUsers className={isActive ? "text-sky-500" : "text-white"} />;
    }
  };

  return (
    <div
      className={`md:block bg-slate-600 h-screen w-full md:w-48 fixed z-50 md:relative origin-left overflow-hidden duration-300 md:scale-x-100 ${
        !open ? "transform scale-x-0" : ""
      }`}
    >
      <div className="overflow-y-auto h-full">
        <FaTimes
          className="text-white absolute right-5 top-2 text-xl md:hidden cursor-pointer"
          onClick={toggle}
        />
        <img
          alt="logo"
          src="/img/logos/tt-logo.png"
          className="block m-auto mt-5 mb-2"
        />
        <ul className="px-3">
          {routes.map((r, i) => {
            const isActive = checkIfActive(r.route);
            const hasAccess =
              userData.role.permissions[r.route as keyof PermissionsInterface]
                ?.read;
            if (hasAccess) {
              return (
                <li onClick={toggle} key={`route-index-${i}`}>
                  <SidebarLink
                    to={r.route}
                    label={r.label}
                    icon={getLinkIcon(r.route, isActive)}
                    active={isActive}
                  />
                </li>
              );
            }
          })}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
