import { create } from "zustand";
import { RoleInterface } from "../interfaces/roleInterface";
import roleApi from "../services/rolesApi";
import { errorNotification, successNotification } from "../utils/notification";

const rolesInit: RoleInterface[] = [];

interface RoleStoreInterface {
  roles: RoleInterface[];
  loading: boolean;
  error: string;
  resetError: () => void;
  getData: () => void;
  saveData: (role: RoleInterface) => void;
}

const useRoleStore = create<RoleStoreInterface>((set) => ({
  roles: rolesInit,
  loading: false,
  error: "",
  resetError: () => {
    set({ error: "" });
  },
  getData: async () => {
    try {
      set({ loading: true, error: "" });
      const response = await roleApi.getRoles();
      if (response.ok && response.data) {
        const { data } = response;
        set({ roles: data, loading: false });
      } else {
        set({ loading: false });
        errorNotification(
          "Oops! algo salió mal, inténtalo de nuevo más tarde."
        );
      }
    } catch (error) {
      set({ loading: false });
      errorNotification("Oops! algo salió mal, inténtalo de nuevo más tarde.");
    }
  },
  saveData: async (role: RoleInterface) => {
    try {
      set({ loading: true, error: "" });
      const response = await roleApi.saveRoles(role);
      // Pendiente ver mejores practicas de backend para guardar y paginar contenido
      if (response.ok && response.data) {
        const { data } = response; // This line retrieves the data from the response object
        set({ loading: false, error: "" });
        successNotification("Datos guardados con éxito!");
        await useRoleStore.getState().getData();
      } else {
        set({ loading: false, error: response.problem as string });
        errorNotification(
          "Oops! algo salió mal, inténtalo de nuevo más tarde."
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      errorNotification("Oops! algo salió mal, inténtalo de nuevo más tarde.");
      set({ loading: false });
    }
  },
}));

export default useRoleStore;
