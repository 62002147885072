import * as Yup from "yup";

const productSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Nombre demaciado corto")
    .required("Campo requerido"),
  categoryId: Yup.string().required("Campo requerido"),
  price: Yup.number().required("Campo requerido"),
  cost: Yup.number().required("Campo requerido"),
});

export default productSchema;
