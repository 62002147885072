interface TitleInterface {
    title: string;
}

const Title = ({ title }: TitleInterface) => {
    return <div className="border-b border-primary mb-2">
        <h1 className="text-slate-500 text-2xl font-medium">{title}</h1>
    </div>
}

export default Title