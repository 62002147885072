import { OptionType } from "../components/common/Inputs/SelectInput";

export interface OrderStatusInterface {
  _id: number;
  name: string;
}

export const quotationStatus: number = 1;
export const pendingStatus: number = 2;
export const packagingStatus: number = 3;
export const shippedStatus: number = 4;
export const canceledStatus: number = 5;

const orderStatusList: OrderStatusInterface[] = [
  {
    _id: quotationStatus,
    name: "Cotización",
  },
  {
    _id: pendingStatus,
    name: "Pendiente",
  },
  {
    _id: packagingStatus,
    name: "Envasado",
  },
  {
    _id: shippedStatus,
    name: "Enviado",
  },
  {
    _id: canceledStatus,
    name: "Cancelado",
  },
];

export const orderStatusOptionList: OptionType[] = [
  {
    value: quotationStatus,
    label: "Cotización",
  },
  {
    value: pendingStatus,
    label: "Pendiente",
  },
  {
    value: packagingStatus,
    label: "Envasado",
  },
  {
    value: shippedStatus,
    label: "Enviado",
  },
  {
    value: canceledStatus,
    label: "Cancelado",
  },
];

export default orderStatusList;
