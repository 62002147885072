import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../../../components/common/Buttons/Button";
import { PaymentsInterface } from "../../../interfaces/orderInterface";
import formatToMoney from "../../../utils/formatToMoney";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { IPaymentMethods, paymentMethods } from "../../../constants/sistem";
import dateFormated from "../../../utils/dateFormated";

interface Props {
  onEdit?: (payment: PaymentsInterface) => void;
  onDelete?: (payment: PaymentsInterface) => void;
  payments?: PaymentsInterface[];
}

const PaymentsTable = ({ payments, onEdit, onDelete }: Props) => {
  return (
    <div className="border rounded-lg overflow-hidden mt-2 overflow-x-auto">
      <table className="min-w-full">
        <thead className="bg-gray-100">
          <tr>
            <th className="py-2 px-4 border-b">Método de Pago</th>
            <th className="py-2 px-4 border-b">Monto Pagado</th>
            <th className="py-2 px-4 border-b">Fecha de Pago</th>
            <th className="py-2 px-4 border-b">Opciones</th>
          </tr>
        </thead>
        <tbody>
          {payments?.map((item: PaymentsInterface, index) => (
            <tr key={`columns-product-${index}`}>
              <td className="py-2 px-4 border-b">
                {paymentMethods[item.paymentMethod as keyof IPaymentMethods]}
              </td>
              <td className="py-2 px-4 border-b">
                {formatToMoney(item.amount)}
              </td>
              <td className="py-2 px-4 border-b">
                {dateFormated(item.paymentDate!)}
              </td>
              <td className="py-2 px-4 border-b">
                <div className="flex gap-2 items-end justify-start">
                  {onEdit && (
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => onEdit(item)}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </Button>
                  )}
                  {onDelete && (
                    <Button
                      size="small"
                      color="secondary"
                      onClick={() => onDelete(item)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PaymentsTable;
