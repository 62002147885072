import React from "react";
import sisSections, { SectionInterface } from "../../../config/sectionsConfig";
import SectionItem from "./SectionItem";
import {
  PermissionsInterface,
  RoleInterface,
} from "../../../interfaces/roleInterface";
import { FormikProps } from "formik"; // Import FormikProps

interface Props {
  formik: FormikProps<RoleInterface>; // Define the formik prop
}

const sectionsKeys = Object.keys(sisSections) as Array<keyof SectionInterface>;

const Sections = ({ formik }: Props) => {
  return (
    <>
      {sectionsKeys.map((x) => {
        const key = x;
        return (
          <SectionItem
            key={`section-${x}`}
            data={sisSections[key]}
            sectionKey={key as keyof PermissionsInterface}
            formik={formik}
          />
        );
      })}
    </>
  );
};

export default Sections;
