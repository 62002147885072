import React, { useMemo, ButtonHTMLAttributes } from "react";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string;
  inverted?: boolean;
  size: string;
}

const SecondaryButton: React.FC<Props> = ({
  label,
  inverted,
  children,
  size,
  ...buttonProps
}) => {
  const bgColor = useMemo(
    () => (inverted ? "bg-white" : `bg-secondary`),
    [inverted]
  );
  const textColor = useMemo(
    () => (inverted ? `text-secondary` : "text-white"),
    [inverted]
  );

  return (
    <button
      {...buttonProps}
      type="button"
      className={`rounded-md py-2 ${size} font-medium focus:outline-none max-w-xs border border-primary ${bgColor} ${textColor} ${buttonProps.className}`}
    >
      {label ? label : children}
    </button>
  );
};

export default SecondaryButton;
