import React, { ReactElement, useState } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import Sidebar from "./sidebar/Sidebar";
import Topbar from "./Topbar";
import ls, { remove } from "local-storage";
import { useIsAuth } from "../utils/useAuth";
import { validateTokenExpirationDate } from "../utils/auth";

interface Props {
  children?: ReactElement | ReactElement[];
}

const DashboardContainer = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);

  const { userData } = useIsAuth();
  const isTokenExpired = validateTokenExpirationDate(userData?.expireAt);

  if (!userData || isTokenExpired) {
    // user is not authenticated
    return <Navigate to="/" />;
  }

  const handleSidebarToggle = () => {
    setOpen((prev) => !prev);
  };

  const logout = () => {
    remove("userData");
    navigate("/");
  };

  return (
    <>
      <div className="flex flex-col md:flex-row">
        <Sidebar open={open} toggle={handleSidebarToggle} />
        <div className="flex-1 h-screen overflow-y-auto">
          <Topbar toggle={handleSidebarToggle} logout={logout} />
          <div className="px-3 pt-14 pb-5 md:px-10 max-w-screen-2xl mx-auto">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardContainer;
