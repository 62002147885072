import { parseISO, format } from "date-fns";

export const getFullDateFormat = (date?: Date | string): string => {
  if (date) {
    return format(
      typeof date == "string" ? parseISO(date) : date,
      "dd/MM/yyyy"
    );
  }
  return "";
};

export const getShortYear = (date?: Date): string => {
  if (date) {
    return format(date, "yy");
  }
  return "";
};
