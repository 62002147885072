import { isrValue, ivaValue } from "./sistem";

export const removeIVAFromPrice = (
  price: number,
  isMoral: boolean | undefined,
  specialDiscount?: number | null
): number => {
  let res: number = 0;
  if (isMoral) {
    res = price / (1 + (ivaValue - isrValue));
    return res;
  }
  res = price / (1 + ivaValue);
  return res;
};
