import { FormikErrors } from "formik";
import React, { InputHTMLAttributes } from "react";
import { IconType } from "react-icons/lib";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: FormikErrors<FormData>;
  inputRef?: React.RefObject<HTMLInputElement>;
  customClass?: string;
  icon?: IconType;
}

const CustomInput: React.FC<Props> = ({
  label,
  error,
  inputRef,
  customClass,
  icon,
  value,
  ...inputProps
}) => {
  const CustomIcon = icon;
  return (
    <div>
      <label className="block font-medium">{label}</label>
      <div className="relative">
        <input
          className={`border-slate-300 rounded p-2 border w-full h-11 ${
            error ? "border-red-500" : ""
          } ${customClass} ${CustomIcon ? "pr-9" : ""}`}
          ref={inputRef}
          value={value ? value : ""}
          {...inputProps}
        />
        {CustomIcon && (
          <CustomIcon className="absolute right-3 top-0 bottom-0 m-auto" />
        )}
      </div>
      {error && <div className="error text-red-500">{<>{error}</>}</div>}
    </div>
  );
};

export default CustomInput;
