import React from "react";

type Props = {
  title: string;
  index: number;
  setSelectedTab: (index: number) => void;
  isSelected: boolean;
};

const TabTitle: React.FC<Props> = ({
  title,
  index,
  isSelected,
  setSelectedTab,
}) => {
  const style = isSelected ? "mb-3 border-b-2 border-primary" : "mb-3";
  return (
    <li>
      <button className={`${style}`} onClick={() => setSelectedTab(index)}>
        {title}
      </button>
    </li>
  );
};

export default TabTitle;
