import * as Yup from "yup";

const userSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Nombre demaciado corto")
    .required("Campo requerido"),
  lastName: Yup.string()
    .min(2, "Apellidos demaciado corto")
    .required("Campo requerido"),
  userName: Yup.string()
    .min(2, "Nombre de usuario demaciado corto")
    .required("Campo requerido"),
  password: Yup.string()
    .min(8, "Contraseña demaciado corto, almenos debe tener 8 caracteres")
    .required("Campo requerido"),
  roleId: Yup.string().required("Campo requerido"),
});

export default userSchema;
