import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import {
  ApiErrorResponse,
  ApiOkResponse,
  ApiResponse,
  PROBLEM_CODE,
} from "../interfaces/apiResponseInterface";
import axiosTeethTab, { setAuthToken } from "./axiosTeethTab";
import ls, { get } from "local-storage";
import { AuthInterface } from "../interfaces/authInterface";

interface ApiInterface {
  get: <T, U = T>(
    endpoint: string,
    params?: AxiosRequestConfig["params"]
  ) => Promise<ApiResponse<T, U>>;
  post: <T, U = T>(
    endpoint: string,
    params?: AxiosRequestConfig["params"]
  ) => Promise<ApiResponse<T, U>>;
}

const baseUrl = process.env.REACT_APP_APIURL;

const resolveErrorType = (statusCode: number): PROBLEM_CODE => {
  if (statusCode == 401) {
    return "UNAUTHORIZED_ERROR";
  } else if (statusCode >= 400 && statusCode <= 500) {
    return "CLIENT_ERROR";
  } else if (statusCode >= 500) {
    return "SERVER_ERROR";
  } else {
    return "UNKNOWN_ERROR";
  }
};

const responseOk = <T>(data: T): ApiOkResponse<T> => {
  return {
    data: data,
    ok: true,
    problem: null,
    originalError: null,
  };
};

const responseError = (error: any): ApiErrorResponse<any> => {
  const { data } = error.response ?? { data: null };
  return {
    ok: false,
    loading: false,
    data: data && data !== "" ? data : null,
    originalError: error,
    problem: resolveErrorType(error.response?.status || 0),
  };
};

const apiCall: ApiInterface = {
  get: async (endpoint: string, params?: AxiosRequestConfig["params"]) => {
    const config: AxiosRequestConfig = {
      method: "GET",
      url: `${baseUrl}${endpoint}`,
      params,
    };
    try {
      const userData = get<AuthInterface>("userData");
      if (userData.token) {
        axiosTeethTab.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${userData.token}`;
      } else {
        delete axiosTeethTab.defaults.headers.common["Authorization"];
      }
      const response = await axiosTeethTab.get(endpoint, params);
      return responseOk(response.data);
    } catch (error) {
      return responseError(error);
    }
  },
  post: async (endpoint: string, params?: AxiosRequestConfig["params"]) => {
    const config: AxiosRequestConfig = {
      method: "POST",
      url: `${baseUrl}${endpoint}`,
      data: params,
    };
    try {
      const userData = get<AuthInterface>("userData");
      setAuthToken(userData?.token);
      const response = await axiosTeethTab.post(endpoint, params);
      return responseOk(response.data);
    } catch (error) {
      return responseError(error);
    }
  },
};

export default apiCall;
