import { FormikErrors, FormikProps } from "formik";
import { InputHTMLAttributes, useEffect, useState } from "react";
import Checkbox from "../../../components/common/Inputs/Checkbox";
import { UserInterface } from "../../../interfaces/userInterface";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  error?: FormikErrors<FormData>;
  inputRef?: React.RefObject<HTMLInputElement>;
  customClass?: string;
  formik: FormikProps<UserInterface>;
  oldPassword: string;
}

const ChangePassword: React.FC<Props> = ({
  error,
  inputRef,
  customClass,
  formik,
  oldPassword,
  ...inputProps
}) => {
  const [isNewPassowrd, setIsNewPassowrd] = useState<boolean>(false);
  useEffect(() => {
    if (isNewPassowrd) {
      formik.setFieldValue("password", "");
    } else {
      formik.setFieldValue("password", oldPassword);
    }
  }, [isNewPassowrd]);
  return (
    <div className="mb-2">
      <Checkbox
        label="Cambiar contraseña"
        id="chage-password-toggle"
        onChange={(e) => {
          setIsNewPassowrd(!isNewPassowrd);
        }}
        checked={isNewPassowrd}
      />
      {isNewPassowrd && (
        <>
          <label className="block font-medium">Contraseña</label>
          <input
            type="password"
            className={`border-slate-300 rounded p-2 border w-full ${
              error ? "border-red-500" : ""
            } ${customClass}`}
            ref={inputRef}
            {...inputProps}
          />
          {error && <div className="error text-red-500">{<>{error}</>}</div>}
        </>
      )}
    </div>
  );
};

export default ChangePassword;
