import { FormikErrors } from "formik";

interface Props {
  error: String | FormikErrors<FormData>;
}

const CustomError = ({ error }: Props) => {
  return error && <div className="error text-red-500">{<>{error}</>}</div>;
};

export default CustomError;
