import { create } from "zustand";
import { DealerInterface } from "../interfaces/dealerInterface";
import { errorNotification, successNotification } from "../utils/notification";
import dealerApi from "../services/dealerApi";

const rolesInit: DealerInterface[] = [];

interface DealerStoreInterface {
  dealers: DealerInterface[];
  loading: boolean;
  error: string;
  resetError: () => void;
  getData: () => void;
  saveData: (role: DealerInterface) => void;
}

const useDealerStore = create<DealerStoreInterface>((set) => ({
  dealers: rolesInit,
  loading: false,
  error: "",
  resetError: () => {
    set({ error: "" });
  },
  getData: async () => {
    try {
      set({ loading: true, error: "" });
      const response = await dealerApi.getDealers();
      if (response.ok && response.data) {
        const { data } = response;
        set({ dealers: data, loading: false });
      } else {
        set({ loading: false });
        errorNotification(
          "Oops! algo salió mal, inténtalo de nuevo más tarde."
        );
      }
    } catch (error) {
      set({ loading: false });
      errorNotification("Oops! algo salió mal, inténtalo de nuevo más tarde.");
    }
  },
  saveData: async (dealer: DealerInterface) => {
    try {
      set({ loading: true, error: "" });
      const response = await dealerApi.saveDealer(dealer);
      // Pendiente ver mejores practicas de backend para guardar y paginar contenido
      if (response.ok && response.data) {
        const { data } = response; // This line retrieves the data from the response object
        set({ loading: false, error: "" });
        successNotification("Datos guardados con éxito!");
        await useDealerStore.getState().getData();
      } else {
        set({ loading: false, error: response.problem as string });
        errorNotification(
          "Oops! algo salió mal, inténtalo de nuevo más tarde."
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      errorNotification("Oops! algo salió mal, inténtalo de nuevo más tarde.");
      set({ loading: false });
    }
  },
}));

export default useDealerStore;
