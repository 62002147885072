const { format, parseISO } = require("date-fns");
const dateFormated = (date: Date | string): string => {
  if (typeof date == "string") {
    const dateObject = parseISO(date);
    return format(dateObject, "dd/MM/yyyy");
  }
  return format(date, "dd/MM/yyyy");
};

export const stringToDate = (date: Date): Date => {
  return new Date(date);
};

export default dateFormated;
